export const SEARCH_TRUE = 'SEARCH_TRUE'
export const SEARCH_FALSE = 'SEARCH_FALSE'

// Logs the user out
export function enableSearch(payload) {
    return dispatch => {
        dispatch({
            type:SEARCH_TRUE,
            ...payload
        })
   
    }
  }
  export function disableSearch(payload) {
    return dispatch => {
        dispatch({
            type:SEARCH_FALSE
        })
   
    }
  }