import React, { Component } from "react";
import ReactTable from "react-table";
import { Button } from "reactstrap";
import "react-table/react-table.css";
import request from "../utils/axios";
import {
  addFilter,
  removeFilter,
  resetFilter,
} from "../store/actions/filteractions";
import ItemDropdown from "../components/ItemActions/ItemDropdown";
import ItemModal from "../components/ItemActions/ItemModal";
import fieldTypes from "../utils/fieldTypes";
import params from "../utils/requestParams";
import { connect } from "react-redux"; //reduxu
import { withI18n } from "react-i18next";
import EditModal from "../components/ItemActions/EditModal";

export class Table extends Component {
  constructor(props) {
    super(props);
    let initialSortObj = {};

    if (this.props.view.default_sort !== undefined) {
      initialSortObj[this.props.view.default_sort] = "asc";
    }

    let currentSort = this.props.view.default_sort
      ? this.props.view.default_sort
      : undefined;

    this.state = {
      rows: [],
      selectedItems: {},
      allSelected: false,
      pages: 1000,
      per_page: 25,
      page: 0,
      openModal: false,
      editModal: false,
      item: undefined,
      sortObj: initialSortObj,
      currentSort: currentSort,
      fetching: false,
      editModalAction: undefined,
      editModalFields: {},
      params: "",
      paramsSwitch: false, // used to check change on params - the actual boolean value is not interestig, we are interest in the prev.State !== currentState
      useDefaultFilter: true,
      loading: false,
      viewAction: "",
      newPageActions: [],
      deletionSwitch: false,
      nestedRows: {},
    };
    this.nextActions();
    this.buildTable();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loading !== this.state.loading) {
      this.nextActions();
      this.buildTable();
    }

    if (prevState.useDefaultFilter !== this.state.useDefaultFilter) {
      this.customFetch();
    } else if (
      prevState.page !== this.state.page ||
      prevState.per_page !== this.state.per_page
    ) {
      this.customFetch();
    } else if (prevState.currentSort !== this.state.currentSort) {
      this.customFetch();
    } else if (prevState.paramsSwitch !== this.state.paramsSwitch) {
      this.customFetch();
    } else if (prevState.deletionSwitch !== this.state.deletionSwitch) {
      this.customFetch();
    }
  }

  buildCell = (itemProp, formFieldKey, formField) => {
    return fieldTypes({
      resource: this.props.resource,
      item: itemProp.row._original,
      key: formFieldKey,
      field: formField,
    });
  };

  buildSelectColumn = () => {
    this.columns.unshift({
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            overflow: "visible",
            color: "black",
          },
        };
      },
      Header: (
        <table>
          <tbody>
            <tr>
              <td>
                <input
                  onLoad={this.customFetch}
                  type="checkbox"
                  checked={this.allSelected}
                  onChange={this.handleSelectAll}
                />
              </td>
              <td>
                <Button
                  onClick={this.handleDeleteSelectedItems}
                  color="deoco"
                  className="text-uppercase"
                  size="sm"
                >
                  <i className="fa fa-trash"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      ),
      accessor: "selection",
      width: 60,
      Cell: (rowInfo) => (
        <input
          type="checkbox"
          checked={this.state.selectedItems[rowInfo.row._original.id]}
          onChange={(e) => this.handleCheckbox(rowInfo.row._original.id)}
        />
      ),
    });
  };

  buildActionColumn = () => {
    this.columns.push({
      getProps: (_state, _rowInfo, _column) => ({
        style: {
          overflow: "visible",
          color: "black",
        },
      }),
      Header: this.props.t("Actions"),
      accessor: "actions",
      Cell: (itemId) => (
        <ItemDropdown
          onLoad={this.customFetch}
          compact={false}
          url={this.fixUrl()}
          item={itemId.row._original}
          itemId={itemId.row._original.id}
          actions={this.state.newPageActions}
          editModal={this.editModal}
          refetch={this.customFetch} //reload parent component when delete
        />
      ),
    });
  };

  buildTable = () => {
    let formFieldsArray = Object.keys(this.props.view.fields);
    this.columns = formFieldsArray.map((formFieldKey) => {
      let formField = this.props.view.fields[formFieldKey];

      return {
        Header: this.t(formField.label || formFieldKey),
        accessor: formField.label,
        sortable: true,
        Cell: (itemProp) => {
          return this.buildCell(itemProp, formFieldKey, formField) || null;
        },
      };
    });

    if (!this.props.view.undeletable) {
      this.buildSelectColumn();
    }
    this.buildActionColumn();
  };

  pageSizeChange = (pageSize, pageIndex) => {
    this.setState({
      per_page: pageSize,
      page: pageIndex,
    });
  };

  getProps = () => {
    return {
      style: {
        overflow: "auto",
        height: "calc(100vh - 120px)",
      },
    };
  };

  changeSort = (newSorted, column, shiftKey) => {
    let sortableFields = this.props.view.sortable_fields;
    let columnId = newSorted[0].id;
    let isSortable = sortableFields.includes(columnId);
    var sort = "";
    if (!isSortable) {
      alert("Column cannot be sorted");
    } else {
      if (this.state.sortObj[columnId] === undefined) {
        sort = "asc";
      } else {
        switch (this.state.sortObj[columnId]) {
          case "asc":
            sort = "desc";
            break;
          case "desc":
            sort = "asc";
            break;
          default:
            sort = "undefined";
            break;
        }
      }
      let newSortObj = { ...this.state.sortObj };
      newSortObj[columnId] = sort;
      this.setState({
        sortObj: newSortObj,
        currentSort: columnId,
      });
    }
  };

  getTdProps = (state, rowInfo, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        if (
          column.id !== "actions" &&
          column.id !== "selection" &&
          column.id !== "expander" &&
          !column.nested &&
          this.state.viewAction
        ) {
          this.handleRowClick(rowInfo);
        }
        if (column.id === "expander") {
          let expanded = this.state.expanded;
          expanded[rowInfo.viewIndex] = !expanded[rowInfo.viewIndex];
          this.setState({ expanded: expanded });
        }
      },
      style: {
        maxHeight: "100px",
        maxWidth: "100px",
      },
    };
  };

  handleDeleteSelectedItems = () => {
    let resource = this.props.resource;
    let confirm = window.confirm("are you sure?");
    if (confirm) {
      // delete only the selected -> true
      var toBeDeleted = {};
      var selectedItems = this.state.selectedItems;

      for (var key in selectedItems) {
        if (selectedItems[key]) {
          toBeDeleted[key] = true;
        }
      }
      request(
        { items: Object.keys(toBeDeleted) },
        `/${resource.resource}/bulk_delete_items`,
        "create"
      ).then((response) => {
        let deletionSwitch = this.state.deletionSwitch;
        this.setState({
          deletionSwitch: !deletionSwitch,
          allSelected: false,
          selectedItems: {},
        });
      });
    }
  };

  handleCheckbox = (itemID) => {
    let selectedItems = this.state.selectedItems;
    let previousSelected = selectedItems[itemID];
    selectedItems[itemID] = !previousSelected;
    this.setState({ selectedItems: selectedItems });
  };

  handleSelectAll = () => {
    let allSelected = !this.state.allSelected;
    this.setState({ allSelected: allSelected });

    var selectedItems = {};
    // set all the visible items as selected
    this.state.rows.forEach((r) => {
      selectedItems[r.id] = allSelected;
    });
    this.setState({ selectedItems: selectedItems });
  };

  fixUrl = () => {
    let resource = this.props.resource;
    return resource.source ? resource.source.url : resource.resource;
  };

  handleRowClick = (row) => {
    this.setState({
      openModal: !this.state.modal,
      item: row,
    });
  };

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  };

  closeEditModal = () => {
    this.setState({ editModal: false });
  };

  editModal = (id, action) => {
    this.setState({
      editModal: true,
      editModalAction: action,
      editModalFields: { id, form: action.form },
    });
  };

  /* finds the nested elements via fetch and stores them into an object */

  customFetch = () => {
    let query = "";

    if (this.state.currentSort) {
      let sort = this.state.sortObj[this.state.currentSort];
      let columnId = this.state.currentSort;
      let params = this.state.params ? this.state.params : "";

      query += `&q[s]=${columnId}`;
      query += `+${sort}${params}`;
    }

    this.setState({ loading: true });
    request(
      {},
      `/${this.fixUrl()}?page=${this.state.page}&per_page=${
        this.state.per_page
      }${query}`,
      "index"
    ).then((response) => {
      var responseRows = [];
      responseRows = response.data[this.fixUrl()].map((item) => {
        return { actions: item.id, ...item };
      });
      this.setState({ rows: responseRows, loading: false, fetching: true });
    });
  };

  t = this.props.t;

  nextActions = () => {
    let actions = this.props.view.view_actions.map((action) => {
      let actionThatMatch = this.props.resource.item_actions.filter(
        (resAction) => resAction.id === action
      );
      return actionThatMatch[0];
    });

    //add actions to the state
    let newPageActions = actions.filter((action) => action.id !== "view");
    let viewAction = actions.find((action) => action.id === "view");

    this.setState({
      newPageActions: newPageActions,
      viewAction: viewAction,
    });
  };

  fetchData = (prevState, instance) => {
    if (this.state.fetching) {
      this.setState({ fetching: false });
      return;
    }

    this.setState({ loading: true });

    let sort = this.state.sortObj[this.state.currentSort];
    let columnId = this.state.currentSort;
    let params = this.state.params ? this.state.params : "";
    let requestURL = "";

    requestURL = `/${this.fixUrl()}?page=${prevState.page}&per_page=${
      prevState.pageSize
    }&q[s]=${columnId}+${sort}${params}`;

    request({}, requestURL, "index").then((response) => {
      var responseRows = [];
      responseRows = response.data[this.fixUrl()].map((item) => {
        return { actions: item.id, ...item };
      });
      this.setState({ rows: responseRows });
      this.setState({ loading: false });
    });
  };

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {this.buildTable()}
        <ReactTable
          manual
          sortable={false}
          nextText={t("Next")}
          previousText={t("Previous")}
          loadingText={t("Loading...")}
          noDataText={t("No rows found")}
          pageText={t("Page")}
          ofText={t("of")}
          rowsText={t("rows")}
          onSortedChange={this.changeSort}
          loading={this.state.loading}
          page={this.props.page}
          pageSize={this.props.per_page}
          pages={this.state.pages}
          onFetchData={this.fetchData}
          onPageSizeChange={this.pageSizeChange}
          getProps={this.getProps}
          getTdProps={this.getTdProps}
          data={this.state.rows}
          columns={this.columns}
          style={{ paddingBottom: "60px" }}
        />
        {this.state.openModal && this.state.item && (
          <ItemModal
            item={this.state.item.row._original}
            form={this.state.viewAction.form}
            url={this.fixUrl()}
            comments={this.state.viewAction.comments}
            closeModal={this.closeModal.bind(this)}
            commentsUrl={`${this.props.resource.resource}/${this.state.item.row._original.id}`}
          />
        )}
        {this.state.editModal ? (
          <EditModal
            id={this.state.editModalFields.id}
            url={this.fixUrl()}
            action={this.state.editModalAction}
            resource={this.props.resource}
            closeModal={this.closeEditModal}
            reload={this.customFetch}
            onLoad={this.customFetch}
            form={this.state.editModalFields.form}
          />
        ) : null}
        {this.props.state.filter.filters ? this.enableFilters() : null}
        {this.props.state.filter.reset ? this.resetFilters() : null}
      </React.Fragment>
    );
  }
  resetFilters = () => {
    this.props.filterOff();
    let paramsSwitch = this.state.paramsSwitch;
    this.setState({ params: undefined, paramsSwitch: !paramsSwitch });
    return null;
  };

  enableFilters = () => {
    this.props.filterOff();
    let p = params(
      this.props.state.filter.filters,
      this.props.resource.filters.form
    );
    let paramsSwitch = this.state.paramsSwitch;
    this.setState({ params: p, paramsSwitch: !paramsSwitch });
    return null;
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    filterOn: (payload) => {
      dispatch(addFilter(payload));
    },
    filterOff: () => {
      dispatch(removeFilter());
    },
    filterReset: () => {
      dispatch(resetFilter());
    },
  };
};
const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(Table));
