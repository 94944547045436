import TextFieldComponent from "formiojs/components/textfield/TextField";

export default class ColorComponent extends TextFieldComponent {
  static schema(...extend) {
    return TextFieldComponent.schema(
      {
        type: "color",
        label: "Color",
        key: "color",
        inputTtype: "color"
      },
      ...extend
    );
  }

  static get builderInfo() {
    return {
      title: "Color",
      group: "advanced",
      icon: "fa fa-at",
      documentation: "http://help.form.io/userguide/#email",
      weight: 10,
      schema: ColorComponent.schema()
    };
  }

  get defaultSchema() {
    return ColorComponent.schema();
  }

  get inputInfo() {
    const info = super.inputInfo;
    info.type = "input";
    info.attr.type = "color";
    return info;
  }
}
