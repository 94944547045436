export default {
  locale: "it",
  invalid_rrule:
    "You provided an invalid RRule value to component. '%{value}' is not a correct RRule string.",
  months: {
    jan: "Gen",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    jun: "Giu",
    jul: "Lug",
    aug: "Aug",
    sep: "Sep",
    oct: "Ott",
    nov: "Nov",
    dec: "Dic",
  },
  days_short: {
    mon: "Lun",
    tue: "Mar",
    wed: "Mer",
    thu: "Gio",
    fri: "Ven",
    sat: "Sab",
    sun: "Dom",
  },
  days: {
    monday: "Lunedì",
    tuesday: "Martedì",
    wednesday: "Mercoledì",
    thursday: "Thursday",
    friday: "Venerdì",
    saturday: "Sabato",
    sunday: "Domenica",
    day: "Giorno",
    weekday: "Giorno della settimana",
    "weekend day": "Giorno della settimana",
  },
  numerals: {
    first: "First",
    second: "Second",
    third: "Third",
    fourth: "Fourth",
    last: "Ultimo",
  },
  start: {
    label: "Inizio",
    tooltip: "Seleziona data di inizio",
  },
  repeat: {
    label: "Ripeti",
    yearly: {
      label: "Annualmente",
      on: "di",
      on_the: "per il",
      of: "di",
    },
    monthly: {
      label: "Monthly",
      every: "ogni",
      months: "mese/i",
      on_day: "per il giorno",
      on_the: "per il ",
    },
    weekly: {
      label: "Settimanale",
      every: "ogni",
      weeks: "settimana/e",
    },
    daily: {
      label: "Giornaliero",
      every: "ogni",
      days: "giorno/i",
    },
    hourly: {
      label: "Orario",
      every: "ogni",
      hours: "ora/e",
    },
  },
  end: {
    label: "fino a ",
    tooltip: "Seleziona data di fine",
    never: "Mai",
    after: " dopo",
    on_date: "la data (esclusa)",
    executions: "esecuzioni.",
  },
};
