

  // The auth reducer. The starting state sets authentication
  // based on a token being in local storage. In a real app,
  // we would also want a util to check if the token is expired.
  let bootstrapReducer=(initialState) => {
      return function bootstrap(state=initialState, action) {
          return state
    }
  }
  
  export default bootstrapReducer
  