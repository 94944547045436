import React from 'react';


class Dashboard extends React.Component {
 
  render() {
    return (
      <div>
         Dashboard
      </div>
    );
  }
}

export default Dashboard;
