import { getComponent } from "formiojs/utils/formUtils.js";
import moment from "moment";
export let getType = (key, form) => {
  if (getComponent(form, key)) {
    let type = getComponent(form, key).titan_field;
    if (type) {
      return type.type;
    }
    return undefined;
  }
};
export default (filters, form) => {
  let params = "";
  for (var key in filters) {
    if (filters[key].needle) {
      params += handleNeedle(form, filters, key);
    } else if (filters[key].bool) {
      params += handleBool(form, filters, key);
    }
  }
  return params;
};

function handleNeedle(form, filters, key) {
  let params = "";
  let value = undefined;
  if (filters.hasOwnProperty(key)) {
    let filter = filters[key];
    if (getType(key + ".needle", form.components) === "date") {
      filter.needle = moment(filter.needle).valueOf() / 1000;
    }
    if (Array.isArray(filter.needle)) {
      value = "";
      filter.needle.map((relation) => {
        value = value + relation.id + ",";
        return 0;
      });
      value = value.slice(0, -1);
    } else if (typeof filter.needle === "object") {
      value = filter.needle.id;
    } else {
      value = filter.needle;
    }
    params = `&q[${key}_${filter.predicate}]=${value}`;
  }
  return params;
}

function handleBool(form, filters, key) {
  if (filters.hasOwnProperty(key)) {
    let value = filters[key].bool;
    value = value === "is_true" ? true : false;
    return `&q[${key}_${value}]=1`;
  }
  return "";
}
