
import {
    NOTIFICATION_TRUE, NOTIFICATION_FALSE
} from '../actions/notificationActions'

function NOTIFICATION(state = {
    notification: false,
    title: "",
    message: "",
    level: ""
}, action) {

    switch (action.type) {

        case NOTIFICATION_TRUE:
            return {
                ...state,
                notification: true,
                title: action.payload.title,
                message: action.payload.message,
                level: action.payload.level
            };
        case NOTIFICATION_FALSE:
            return {
                ...state,
                notification: false,
                title: "",
                message: "",
                level: ""
            }
        default:
            return state
    }
}

export default NOTIFICATION;
