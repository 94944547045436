
import {
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS
} from '../actions/authActions'

// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
function Auth(state = {
    isFetching: false,
    isAuthenticated: localStorage.getItem('token') ? true : false
  }, action) {
   
  switch (action.type) {
    case LOGIN_REQUEST:
      return{
        isFetching: true,
        isAuthenticated: false,
        user: action.creds,
        ...state
      }
    case LOGIN_SUCCESS:
      return {...state,
        isFetching: false,
        isAuthenticated: true
      }
    case LOGIN_FAILURE:
      return{
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      }
    case LOGOUT_SUCCESS:
    return {...state,
      isFetching: false,
      isAuthenticated: false}
    default:
      return state
  }
}
export default Auth
