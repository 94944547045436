import { connect } from "react-redux";
import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import componentQueries from "react-component-queries";
import {
  STATE_LOGIN,
  STATE_SIGNUP,
  STATE_RESET_PASSWORD_MAILER,
  STATE_UPDATE_PASSWORD_MAILER,
  STATE_ACCEPT_INVITATION
} from "components/AuthForm";
import GAListener from "components/GAListener";
import { EmptyLayout, LayoutRoute, MainLayout } from "components/Layout";
import AuthPage from "pages/AuthPage";
import ResetPasswordMailerPage from "pages/ResetPasswordMailerPage";
import UpdatePasswordMailerPage from "pages/UpdatePasswordMailerPage";
import AcceptInvitationPage from "pages/AcceptInvitationPage";

import "./styles/reduction.css";
import routesFunc from "./routes";
import ProtectedRoute from "./components/auth/ProtectedRoute";

const getBasename = () => {
  return `/`;
};

class App extends React.Component {
  render() {
    let dynamicRoutes = routesFunc(this.props.state.resources).map(
      (route, index) => {
        if (route.auth_required) {
          return (
            <ProtectedRoute
              key={index}
              exact
              path={route.path}
              layout={MainLayout}
              component={route.component}
            />
          );
        } else {
          return (
            <LayoutRoute
              key={index}
              exact
              path={route.path}
              layout={MainLayout}
              component={route.component}
            />
          );
        }
      }
    );

    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />
            <LayoutRoute
              exact
              path="/signup"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
              )}
            />
            <LayoutRoute
              exact
              path="/reset_password_mailer"
              layout={EmptyLayout}
              component={props => (
                <ResetPasswordMailerPage
                  {...props}
                  authState={STATE_RESET_PASSWORD_MAILER}
                />
              )}
            />
            <LayoutRoute
              exact
              path="/accept_invitation/:token"
              layout={EmptyLayout}
              component={props => (
                <AcceptInvitationPage
                  {...props}
                  authState={STATE_ACCEPT_INVITATION}
                />
              )}
            />{" "}
            <LayoutRoute
              exact
              path="/update_password_mailer/:token"
              layout={EmptyLayout}
              component={props => (
                <UpdatePasswordMailerPage
                  {...props}
                  authState={STATE_UPDATE_PASSWORD_MAILER}
                />
              )}
            />
            {dynamicRoutes}
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (width > 576 && width < 767) {
    return { breakpoint: "sm" };
  }

  if (width > 768 && width < 991) {
    return { breakpoint: "md" };
  }

  if (width > 992 && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

const mapStateToProps = state => {
  return {
    state: state.dashboard
  };
};

export default connect(mapStateToProps)(componentQueries(query)(App));
