import React from "react";
import RRuleGenerator from "react-rrule-generator";
import { withRouter } from "react-router-dom";
import request from "../../utils/axios";
import { connect } from "react-redux"; //redux
import {
  fireNotification,
  disableNotification,
} from "../../store/actions/notificationActions";
import { Redirect } from "react-router";

import { submitBehaivour } from "../../utils/formTypes";
import { withI18n } from "react-i18next";
import { Col, Button, ButtonGroup, Row, Container } from "reactstrap";
import italianTranslation from "../translations/rrule_generator/italian";
class ItemActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rrule: "FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR;COUNT=3",
      rerender: false,
      loading: false,
    };
    this.initialize();
  }
  initialize = () => {};
  skip = () => {
    this.setState(
      submitBehaivour(
        this.props.resource,
        this.props.itemId,
        { behavior: "redirect_to_default_view" },
        this.state.rerender
      )
    );
  };

  chooseTranslation = () => {
    let lang = localStorage.getItem("lang");
    switch (lang) {
      case "it":
        return italianTranslation;
      default:
        return undefined;
    }
  };

  clone = () => {
    let submitObj = {
      field: this.props.action.rrule_field,
      repetition_rule: this.state.rrule,
    };

    if (this.props.action.overwrite_attributes) {
      submitObj.overwrite_attributes = this.props.action.overwrite_attributes;
    }

    this.setState({ loading: true });
    request(submitObj, `${this.props.url}/schedule`, "create").then(
      (response) => {
        this.setState({ loading: false });
        if (!this.props.closeModal) {
          if (this.props.action.after_success) {
            this.setState(
              submitBehaivour(
                this.props.resource,
                this.props.itemId,
                this.props.action.after_success,
                this.state.rerender
              )
            );
          }
        } else {
          this.props.closeModal.closeModal();
          this.props.closeModal.reload();
        }
        this.props.fireNotification({
          title: "",
          message: this.t("Clone Succesful"),
          level: "success",
        });
      }
    );
  };
  t = this.props.t;
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {this.state.to ? <Redirect to={this.state.to} /> : null}
        {this.state.loading ? (
          <i
            class="fa fa-circle-o-notch fa-spin"
            style={{
              "font-size": "54px",
              marginLeft: "50%",
              marginBottom: "50px",
            }}
          ></i>
        ) : (
          <Container fluid>
            <Row>
              <Col>
                <RRuleGenerator
                  //translations={(key, reps) => t(key, reps)}
                  translations={this.chooseTranslation()}
                  config={{
                    repeat: ["Hourly", "Daily", "Weekly"],
                    yearly: "on the",
                    monthly: "on",
                    end: ["After", "On date"],
                    weekStartsOnSunday: true,
                    hideError: true,
                  }}
                  onChange={(rrule) => this.setState({ rrule })}
                />
              </Col>
            </Row>

            <Row>
              <Col></Col>
              <Col>
                <ButtonGroup>
                  <Button
                    onClick={() => {
                      this.skip();
                    }}
                    color="warning"
                  >
                    {t("Skip")}
                  </Button>
                  <Button
                    onClick={() => {
                      this.clone();
                    }}
                    color="success"
                  >
                    {t("Clone")}
                  </Button>
                </ButtonGroup>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fireNotification: (payload) => {
      dispatch(fireNotification(payload));
    },
    disableNotification: () => {
      dispatch(disableNotification());
    },
  };
};

const mapStatetoProps = (state) => {
  return {
    state: state,
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withI18n()(withRouter(ItemActions)));
