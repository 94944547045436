import React from "react";
//import { HandleRequest } from "./api_handler";
import FormioCard from "../components/FormioCard";
import { Col, Row, Button } from "reactstrap";
import { Card, CardBody } from "reactstrap";
import EditModal from "../components/ItemActions/EditModal";
import ItemModal from "../components/ItemActions/ItemModal";
import request from "../utils/axios";
import { connect } from "react-redux"; //redux
import params from "../utils/requestParams";
import {
  addFilter,
  removeFilter,
  resetFilter
} from "../store/actions/filteractions";
import { withI18n } from "react-i18next";

import ItemDropdown from "../components/ItemActions/ItemDropdown";

class Cards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: "",
      page: 0
    };
    this.initialize();
  }

  fixUrl = () => {
    let resource = this.props.resource;
    return resource.source ? resource.source.url : resource.resource;
  };
  defineActions = () => {
    let actions = this.props.view.view_actions.map(action => {
      let actionThatMatch = this.props.resource.item_actions.filter(
        resAction => resAction.id === action
      );
      return actionThatMatch[0];
    });
    //add actions to the state
    let newPageActions = actions.filter(action => action.id !== "view");
    let viewAction = actions.find(action => action.id === "view");
    this.setState({
      newPageActions,
      viewAction
    });
  };
  closeEditModal = () => {
    this.setState({ editModal: false });
  };
  editModal = (id, action) => {
    this.setState({
      editModal: true,
      editModalFileds: { id, form: action.form }
    });
  };
  generateCards = (items, append) => {
    let cards = items.map((item, index) => (
      <Col key={index} sm={6} md={4} lg={3}>
        <Card className="flex-row">
          <CardBody>
            <Col sm={{ size: 4, offset: 8 }}>
              <ItemDropdown
                url={this.props.resource.resource}
                itemId={item.id}
                refetch={this.customFetch}
                editModal={this.editModal}
                actions={this.state.newPageActions}
              />
            </Col>
            <div
              onClick={() => {
                this.openModal(item);
              }}
            >
              <FormioCard
                key={index}
                submission={{ ...item }}
                form={this.props.view.content}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    ));
    if (append) {
      cards = this.state.cards.concat(cards);
    }
    this.setState({ cards });
  };
  customFetch = append => {
    let params = this.state.params ? this.state.params : "";
    let sort = this.props.view.default_sort
      ? `&q[s]=${this.props.view.default_sort}+asc`
      : "";
    request(
      {},
      `/${this.fixUrl()}?page=${this.state.page}${params}${sort}`,
      "index"
    ).then(response => {
      this.generateCards(response.data[this.fixUrl()], append);
    });
  };
  initialize() {
    this.defineActions();
    let sort = this.props.view.default_sort
      ? `?q[s]=${this.props.view.default_sort}+asc`
      : "";
    request({}, "/" + this.fixUrl() + sort, "index").then(response => {
      this.generateCards(response.data[this.fixUrl()]);
    });
  }
  resetFilters = () => {
    this.props.filterOff();
    this.setState({ params: undefined }, () => {
      this.customFetch(false);
    });
    return null;
  };
  loadMore = () => {
    this.setState({ page: this.state.page + 1 }, () => {
      this.customFetch(true);
    });
  };
  enableFilters = () => {
    this.props.filterOff();
    let p = params(this.props.state.filter.filters);
    this.setState({ params: p, page: 0 }, () => {
      this.customFetch(false);
    });
    return null;
  };
  openModal = item => {
    if (this.state.viewAction) {
      this.setState({ openModal: true, item });
    }
  };
  closeModal = () => {
    this.setState({ openModal: false });
  };
  render() {
    return (
      <React.Fragment>
        {this.state.editModal ? (
          <EditModal
            id={this.state.editModalFileds.id}
            url={this.fixUrl()}
            resource={this.props.resource}
            closeModal={this.closeEditModal}
            reload={this.customFetch}
            form={this.state.editModalFileds.form}
          />
        ) : null}
        {this.state.openModal && this.state.item && (
          <ItemModal
            item={this.state.item}
            form={this.state.viewAction.form}
            url={this.fixUrl()}
            comments={this.state.viewAction.comments}
            closeModal={this.closeModal.bind(this)}
            commentsUrl={`${this.props.resource.resource}/${this.state.item.id}`}
          />
        )}
        <div className="cards-container">
          <Row>{this.state.cards}</Row>
          {this.props.state.filter.filters ? this.enableFilters() : null}
          {this.props.state.filter.reset ? this.resetFilters() : null}
        </div>
        <div className="row justify-content-center">
          <Button
            onClick={() => {
              this.loadMore();
            }}
            color="deoco"
          >
            Add More
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    filterOn: payload => {
      dispatch(addFilter(payload));
    },
    filterOff: () => {
      dispatch(removeFilter());
    },
    filterReset: () => {
      dispatch(resetFilter());
    }
  };
};
const mapStateToProps = state => {
  return {
    state: state
  };
};
export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(Cards));
