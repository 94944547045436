import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import ItemModal from "./ItemActions/ItemModal";
import ItemDropdown from "./ItemActions/ItemDropdown";
import fieldTypes from "../utils/fieldTypes";
import EditModal from "./ItemActions/EditModal";

class CustomCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: ""
    };

    this.handleCardClick = this.handleCardClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
  }

  handleCardClick = () => {
    this.setState({
      openModal: !this.state.modal
    });
  };
  closeModal() {
    this.setState({
      openModal: false
    });
  }

  closeEditModal = () => {
    this.setState({ editModal: false });
  };
  openEditModal = (id, action) => {
    this.setState({
      editModal: true,
      editModalFileds: { id, form: action.form }
    });
  };
  render() {
    var keys = Object.keys(this.props.fields);

    return (
      <Card size="xs" id={this.props.key} className="custom-card">
        <Col sm={{ size: 4, offset: 8 }}>
          <ItemDropdown
            refetch={this.props.refetch}
            url={this.props.url}
            item={this.props.customProps}
            editModal={this.openEditModal}
            itemId={this.props.customProps.id}
            actions={this.props.newPageActions}
          />
        </Col>
        {this.props.fields
          ? keys.map((key, index) => {
              return (
                <CardBody
                  onClick={this.handleCardClick}
                  key={index}
                  className="custom-card-body"
                >
                  {fieldTypes({
                    resource: this.props.resource,
                    item: this.props.customProps,
                    key,
                    field: this.props.fields[key]
                  })}
                </CardBody>
              );
            })
          : null}
        {this.state.openModal && (
          <ItemModal
            url={this.props.resource.display_name}
            item={this.props.customProps}
            form={this.props.viewAction.form}
            closeModal={this.closeModal}
          />
        )}

        {this.state.editModal ? (
          <EditModal
            id={this.state.editModalFileds.id}
            url={this.props.resource.resource}
            resource={this.props.resource}
            closeModal={this.closeEditModal}
            reload={this.props.reload}
            form={this.state.editModalFileds.form}
          />
        ) : null}
      </Card>
    );
  }
}

export default CustomCard;
