import request from "../../utils/axios";
import { AuthCodeRequest } from "../../utils/axios";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_MAILER_SUCCESS = "RESET_PASSWORD_MAILER_SUCCESS";
export const UPDATE_PASSWORD_MAILER_SUCCESS = "UPDATE_PASSWORD_MAILER_SUCCESS";

// function requestLogout() {
//   return {
//     type: LOGOUT_REQUEST,
//     isFetching: true,
//     isAuthenticated: true
//   }
// }

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

// Logs the user out
export function logoutUser() {
  return (dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("expire");
    dispatch(receiveLogout());
  };
}

// function requestLogin(creds) {
//   return {
//     type: LOGIN_REQUEST,
//     isFetching: true,
//     isAuthenticated: false,
//     creds
//   }
// }

function receiveLogin(data) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
  };
}

function receiveUpdatePasswordMailer(data) {
  return {
    type: UPDATE_PASSWORD_MAILER_SUCCESS,
    hasUpdatedPasswordMailer: true,
  };
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
}

let setLang = (profile) => {
  let lang = undefined;
  if (profile.locale) {
    lang = profile.locale;
  } else if (profile.lang) {
    lang = profile.lang;
  } else if (profile.country) {
    lang = profile.country;
  } else {
    lang = "EN";
  }

  lang = lang.toLowerCase(lang);
  console.log("lang has been set to...", lang);
  localStorage.setItem("lang", lang);
};
export function loginUser(creds, isSignup) {
  return (dispatch) => {
    if (isSignup) {
      localStorage.setItem("token", creds.token);
      localStorage.setItem("expire", creds.expire);
      dispatch(receiveLogin(creds));
      return;
    } else {
      // We dispatch requestLogin to kickoff the call to the API
      return request(creds, "/login", "create")
        .then((response) => {
          let data = response;
          if (!response) {
            // If there was a problem, we want to
            // dispatch the error condition
            dispatch(loginError(data.error));
            alert("wrong password or username");
            return Promise.reject(data);
          } else {
            // If login was successful, set the token in local storage

            localStorage.setItem("token", data.token);
            localStorage.setItem("expire", data.expire);
            request({}, "profile", "index").then((response) => {
              let profile = JSON.stringify(response);
              localStorage.setItem("profile", profile);
              setLang(response);
            });

            // Dispatch the success action
            dispatch(receiveLogin(data));
          }
        })
        .catch((err) => console.log("Error: ", err));
    }
  };
}

export function resetPasswordMailer(data) {
  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    return request(data, "/password/reset", "create").then((response) => {});
  };
}

export function updatePasswordMailer(data) {
  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    return AuthCodeRequest(data, "/password", "update")
      .then((response) => {
        let data = response;
        dispatch(receiveUpdatePasswordMailer(data));
      })
      .catch((err) => {
        alert("Error updating password");
        console.log(err);
      });
  };
}

export function acceptInvitation(data) {
  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    return AuthCodeRequest(data, "/accept_invitation", "create")
      .then((response) => {})
      .catch((err) => {
        alert("Error accepting invitation");
        console.log(err);
      });
  };
}

export function refreshToken() {
  return (dispatch) => {
    request(
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
      localStorage.getItem("base_url") + "/refresh_token",
      "index"
    )
      .then((response) => {
        if (response.code !== 200) {
          // If there was a problem, we want to
          // logout the user

          dispatch(logoutUser());
        } else {
          // If login was successful, set the token in local storage
          localStorage.setItem("token", response.token);
          localStorage.setItem("expire", response.expire);

          // Dispatch the success action
        }
      })
      .catch((error) => {
        dispatch(logoutUser());
        console.log(error);
      });
  };
}
