import React from "react";
import ReactMapboxGl, { Marker, Cluster, Popup } from "react-mapbox-gl";
import request from "../utils/axios";
import fitBoundsFunc from "../utils/fitBounds";
import { ZoomControl } from "react-mapbox-gl";
import { connect } from "react-redux"; //redux
import params from "../utils/requestParams";
import {
  addFilter,
  removeFilter,
  resetFilter
} from "../store/actions/filteractions";
import { withI18n } from "react-i18next";
import MapModal from "../components/MapModal";

let clusterStyle = {
  width: 30,
  height: 30,
  borderRadius: "50%",
  backgroundColor: "black",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  border: "2px solid #56C498",
  cursor: "pointer"
};
let markerStyle = {
  width: 20,
  height: 20,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};
// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const MapGl = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiZXJhbGQxNCIsImEiOiJjam1rNzhsY28waTc0M2ttdWJmNnVuODFyIn0.iwF5g1uF6JTzOp6_3f3Tgw"
});

class MapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: undefined,
      markers: [],
      fitBounds: [],
      page: 0
    };
    this.initialize();
  }

  fixUrl = () => {
    let resource = this.props.resource;
    return resource.source ? resource.source.url : resource.resource;
  };
  getLocationKey = locationObj => {
    return Object.keys(locationObj)[0];
  };
  generateMap = (items, append) => {
    let locationFieldKey = this.props.view.default_location_field;
    this.setState({ locationFieldKey });
    let fitBounds = this.state.fitBounds;
    let markers = items.map((item, index) => {
      let itemLocation;
      if (item[this.getLocationKey(locationFieldKey)]) {
        itemLocation = item[this.getLocationKey(locationFieldKey)].location;
        fitBounds.push([itemLocation.longitude, itemLocation.latitude]);
        return (
          <Marker
            key={index}
            item={item}
            style={markerStyle}
            onClick={() => {
              this.markerClick(item, [
                itemLocation.longitude,
                itemLocation.latitude
              ]);
            }}
            coordinates={[itemLocation.longitude, itemLocation.latitude]}
            anchor="bottom"
          >
            <i className="fas fa-map-marker-alt fa-2x"></i>
          </Marker>
        );
      }
      return <div></div>;
    });
    markers = markers.filter(function(el) {
      return el != null;
    });
    if (append) {
      markers = markers.concat(this.state.markers);
    }
    this.setState({ markers, fitBounds: fitBoundsFunc(fitBounds) });
  };
  customFetch = append => {
    let params = this.state.params ? this.state.params : "";
    request(
      {},
      `/${this.fixUrl()}?page=${this.state.page}${params}`,
      "index"
    ).then(response => {
      this.generateMap(response.data[this.props.resource.resource], append);
    });
  };
  initialize() {
    let actions = this.props.view.view_actions.map(action => {
      let actionThatMatch = this.props.resource.item_actions.filter(
        resAction => resAction.id === action
      );
      return actionThatMatch[0];
    });

    //add actions to the state
    let newPageActions = actions.filter(action => action.id !== "view");
    let viewAction = actions.find(action => action.id === "view");

    this.setState({
      newPageActions,
      viewAction
    });
    request({}, "/" + this.fixUrl(), "index").then(response => {
      this.generateMap(response.data[this.props.resource.resource]);
    });
  }
  markerClick = (item, coordinates) => {
    this.setState(
      {
        clusterModal: false
      },
      () => {
        this.moveTo(coordinates);
        this.setState({ markerModal: true, markerData: item });
      }
    );
  };
  clusterClick = (coordinates, leaves) => {
    this.setState({
      markerModal: false,
      clusterModal: true,
      clusterData: leaves
    });
  };
  onMove = () => {
    if (this.state.popup || this.state.itemPopup) {
      this.setState({
        popup: undefined,
        itemPopup: undefined
      });
    }
  };

  clusterMarker = (coordinates, markersNumber, getLeaves) => (
    <Marker
      onClick={this.clusterClick.bind(this, coordinates, getLeaves)}
      coordinates={coordinates}
      style={clusterStyle}
    >
      {markersNumber}
    </Marker>
  );
  moveTo = coordinates => {
    this.setState({ center: coordinates, zoom: [13] });
  };
  handleZoom = (map, zoomDiff) => {
    zoomDiff < 0 ? map.zoomOut() : map.zoomIn();
  };
  loadMore = () => {
    this.setState({ page: this.state.page + 1 }, () => {
      this.customFetch(true);
    });
  };
  reload = () => {
    this.customFetch();
    this.setState({ clusterModal: false, markerModal: false });
  };
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {this.state.clusterModal ? (
          <MapModal
            display_name={this.props.view.display_name}
            markerClick={this.markerClick}
            actions={this.state.newPageActions}
            resource={this.props.resource}
            reload={this.reload}
            res={this.fixUrl()}
            clusterData={this.state.clusterData}
          />
        ) : null}
        {this.state.markerModal ? (
          <MapModal
            viewAction={this.state.viewAction}
            actions={this.state.newPageActions}
            resource={this.props.resource}
            reload={this.reload}
            res={this.fixUrl()}
            markerData={this.state.markerData}
          />
        ) : null}
        <div
          className="loadload"
          onClick={() => {
            this.loadMore();
          }}
        >
          {t("Load More")}
        </div>
        <MapGl
          {...(this.state.center && { center: this.state.center })}
          fitBounds={
            this.state.fitBounds.length > 0
              ? this.state.fitBounds
              : [[40.0, 40.0], [40.0, 50.0]]
          }
          // eslint-disable-next-line react/style-prop-object
          style="mapbox://styles/mapbox/streets-v9"
          onMove={this.onMove}
          zoom={this.state.zoom ? this.state.zoom : undefined}
          containerStyle={{
            height: "calc(100vh - 120px)"
          }}
        >
          <ZoomControl
            position="bottom-right"
            zoomDiff="1"
            onControlClick={this.handleZoom.bind(this)}
          />
          {this.state.markers && (
            <Cluster ClusterMarkerFactory={this.clusterMarker} minZoom={0}>
              {this.state.markers}
            </Cluster>
          )}

          {this.state.popup && (
            <Popup
              style={{ width: 70, height: 70 }}
              offset={[0, -50]}
              coordinates={this.state.popup.coordinates}
            >
              {this.state.popup.leaves.map((Leaf, index) => {
                return (
                  <div
                    key={index}
                    onClick={this.moveTo.bind(
                      this,
                      Leaf.props[this.state.locationFieldKey]
                    )}
                  >
                    item {index}
                  </div>
                );
              })}
            </Popup>
          )}
        </MapGl>
        ) }{this.props.state.filter.filters ? this.enableFilters() : null}
        {this.props.state.filter.reset ? this.resetFilters() : null}
      </React.Fragment>
    );
  }
  resetFilters = () => {
    this.props.filterOff();
    this.setState({ params: undefined }, () => {
      this.customFetch(false);
    });
    return null;
  };

  enableFilters = () => {
    this.props.filterOff();
    let p = params(this.props.state.filter.filters);
    this.setState({ params: p, page: 0 }, () => {
      this.customFetch(false);
    });
    return null;
  };
}

const mapDispatchToProps = dispatch => {
  return {
    filterOn: payload => {
      dispatch(addFilter(payload));
    },
    filterOff: () => {
      dispatch(removeFilter());
    },
    filterReset: () => {
      dispatch(resetFilter());
    }
  };
};
const mapStateToProps = state => {
  return {
    state: state
  };
};
export default withI18n()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MapView)
);
