import React from "react";
import { connect } from "react-redux"; //redux
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import request from "../../utils/axios";
import {
  fireNotification,
  disableNotification,
} from "../../store/actions/notificationActions";
import { withI18n } from "react-i18next";

let sortFunc = (a, b) => {
  if (a.weight && b.weight) {
    if (a.weight < b.weight) {
      return -1;
    }
    if (a.weight > b.weight) {
      return 1;
    }
    return 0;
  }
};

class ItemDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }
  componentDidUpdate = () => {};
  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  getDestroyAction = () => {
    let filtered = this.props.actions.filter(
      (action) => action.id === "destroy"
    );
    return filtered[0];
  };
  hasSuccessBehavior = (destroyAction) => {
    return destroyAction.after_success && destroyAction.after_success.behavior;
  };

  behaveAfterDelete = (behavior) => {
    switch (behavior) {
      case "reload":
        console.log("reloading");
        window.location.reload();
    }
  };
  deleteItem = (url, id) => {
    if (window.confirm("Delete item?")) {
      request({}, `/${url}/${id}`, "destroy").then((response) => {
        if (response) {
          this.props.fireNotification({
            title: "",
            message: "Delete Successful",
            level: "success",
          });

          console.log("Deleteeeeeeeeee", this.props);
          let destroyAction = this.getDestroyAction();
          if (this.hasSuccessBehavior(destroyAction)) {
            this.behaveAfterDelete(destroyAction.after_success.behavior);
          }
        }

        if (this.props.refetch) {
          this.props.refetch();
        }
      });
    }
  };
  dynamicAction = (actionLabel, url, requestType) => {
    console.log("inside dynamic");
    if (!this.props.item.user) {
      console.log("inside error");
      this.props.fireNotification({
        title: "",
        message: actionLabel + "  cannot be performed on this item",
        level: "error",
      });
    } else {
      console.log("inside true");
      request({}, url, requestType).then((response) => {
        this.props.fireNotification({
          title: "",
          message: actionLabel + " succesful",
          level: "succees",
        });
      });
    }
  };
  render() {
    const { t } = this.props;
    return (
      <Dropdown
        size="sm"
        lg={{ size: 5, offset: 8 }}
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle color="white" caret>
          {this.props.compact ? (
            <i class="fa fa-cog" aria-hidden="true" />
          ) : (
            t("Actions")
          )}
        </DropdownToggle>
        <DropdownMenu size="xs">
          {(this.props.actions || []).sort(sortFunc).map((action, index) => {
            if (
              (action.id === "edit" ||
                action.id === "clone" ||
                action.id === "bookkeeping") &&
              action.modal
            ) {
              return (
                <DropdownItem
                  key={index}
                  size="xs"
                  onClick={() => {
                    this.props.editModal(this.props.itemId, action);
                  }}
                >
                  <i className={action.icon ? action.icon : null} />
                  {t(`${action.label || action.id}`)}
                </DropdownItem>
              );
            } else {
              switch (action.id) {
                case "destroy":
                  return (
                    <DropdownItem
                      key={index}
                      size="xs"
                      onClick={() => {
                        this.deleteItem(this.props.url, this.props.itemId);
                      }}
                    >
                      <i className={action.icon ? action.icon : null} />
                      {t(`${action.label || action.id}`)}
                    </DropdownItem>
                  );
                case "user_unlock":
                  return (
                    <DropdownItem
                      key={index}
                      size="xs"
                      onClick={() => {
                        this.dynamicAction(
                          action.label,
                          `/users/${
                            this.props.item.user
                              ? this.props.item.user.id
                              : null
                          }/unlock`,
                          "update"
                        );
                      }}
                    >
                      <i className={action.icon ? action.icon : null} />
                      {t(`${action.label || action.id}`)}
                    </DropdownItem>
                  );
                case "user_lock":
                  return (
                    <DropdownItem
                      key={index}
                      size="xs"
                      onClick={() => {
                        this.dynamicAction(
                          action.label,
                          `/users/${
                            this.props.item.user
                              ? this.props.item.user.id
                              : null
                          }/lock`,
                          "update"
                        );
                      }}
                    >
                      <i className={action.icon ? action.icon : null} />
                      {t(`${action.label || action.id}`)}
                    </DropdownItem>
                  );
                default:
                  return (
                    <Link
                      key={index}
                      to={`/${this.props.url}/${this.props.itemId}/${action.id}`}
                    >
                      <DropdownItem size="xs">
                        <i className={action.icon ? action.icon : null} />
                        {t(`${action.label || action.id}`)}
                      </DropdownItem>
                    </Link>
                  );
              }
            }
          })}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fireNotification: (payload) => {
      dispatch(fireNotification(payload));
    },
    disableNotification: () => {
      dispatch(disableNotification());
    },
  };
};

const mapStatetoProps = (state) => {
  return {
    state: state,
  };
};

export default withI18n()(
  connect(mapStatetoProps, mapDispatchToProps)(ItemDropdown)
);
