import axios from "axios";
import { storeInstance } from "../store/store";
import { fireNotification } from "../store/actions/notificationActions";

const customAxios = axios.create({
  timeout: 130000,
  headers: { "Content-Type": "application/json" },
});

customAxios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export function AuthCodeRequest(data, resourceName, action) {
  customAxios.defaults.baseURL = localStorage.getItem("base_url");
  var config = authCodeRequestBuilder(data, resourceName, action);

  const request = customAxios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      let a = fireNotification({
        title: "Request error",
        message: "ERROR: request from server",
        level: "info",
      });
      storeInstance().dispatch(a);
      return Promise.reject(error);
    });
  return request;
}

export default function HandleRequest(data, resourceName, action, opts = {}) {
  customAxios.defaults.baseURL = localStorage.getItem("base_url");
  var config = requestBuilder(data, resourceName, action, opts);
  const request = customAxios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      let a = fireNotification({
        title: "Request error",
        message: "ERROR: request from server",
        level: "info",
      });
      storeInstance().dispatch(a);
      return Promise.reject(error);
    });
  return request;
}

function requestBuilder(payload, resourceName, action, opts = {}) {
  var method = methodAction(action);

  var request = {
    method: methodAction(action),
    url: resourceName,
    data: payload,
  };

  if (method === "GET") {
    delete request.data;
  }

  if (opts.ignoreRoleAssignation) {
    request.headers = {
      "Content-Type": "application/json",
      "X-TITAN-IGNORE-ASSIGNATION": opts.ignoreRoleAssignation,
    };
  }
  return request;
}

function authCodeRequestBuilder(payload, resourceName, action) {
  var method = methodAction(action);
  var request = {
    method: methodAction(action),
    url: resourceName,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Short-Code": payload.auth_code,
    },
  };
  if (method === "GET") {
    delete request.data;
  }

  return request;
}

function methodAction(action) {
  switch (action) {
    case "index":
      return "GET";
    case "create":
      return "POST";
    case "invite":
      return "POST";
    case "update":
      return "PATCH";
    case "destroy":
      return "DELETE";
    default:
      return "OPTIONS";
  }
}
