export const NOTIFICATION_TRUE = 'NOTIFICATION_TRUE'
export const NOTIFICATION_FALSE = 'NOTIFICATION_FALSE'

// function requestLogout() {
//   return {
//     type: LOGOUT_REQUEST,
//     isFetching: true,
//     isAuthenticated: true
//   }
// }

export function fireNotification(payload) {
  return {
    type: NOTIFICATION_TRUE,
    payload
  }
}
export function disableNotification() {
    return {
      type: NOTIFICATION_FALSE
    }
  }

