import React from 'react';

const Footer = () => (
  <React.Fragment>
    
  </React.Fragment>
   
);

export default Footer;
