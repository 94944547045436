import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import request from "../utils/axios";
import {
  addFilter,
  removeFilter,
  resetFilter
} from "../store/actions/filteractions";
import ItemModal from "../components/ItemActions/ItemModal";
import { connect } from "react-redux"; //reduxu
import { withI18n } from "react-i18next";
import EditModal from "../components/ItemActions/EditModal";
import { Table } from "./Table";
class TreeTable extends Table {
  constructor(props) {
    super(props);
    let initialSortObj = {};

    if (this.props.view.default_sort !== undefined) {
      initialSortObj[this.props.view.default_sort] = "asc";
    }

    let currentSort = this.props.view.default_sort
      ? this.props.view.default_sort
      : undefined;

    this.state = {
      rows: [],
      selectedItems: [],
      allSelected: false,
      pages: 1000,
      per_page: 25,
      page: 0,
      expanded: {},
      openModal: false,
      editModal: false,
      item: undefined,
      sortObj: initialSortObj,
      currentSort: currentSort,
      fetching: false,
      editModalAction: undefined,
      editModalFields: {},
      params: "",
      paramsSwitch: false, // used to check change on params - the actual boolean value is not interestig, we are interest in the prev.State !== currentState
      useRootFilter: true,
      loading: false,
      viewAction: "",
      newPageActions: [],
      nestedRows: {}
    };
    this.nextActions();
    this.buildTable();
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (prevState.useRootFilter !== this.state.useRootFilter) {
      this.customFetch();
    }
  }

  buildExpanders = () => {
    let expandField = this.props.view.expand_field;
    // Build our expander column

    this.columns.unshift({
      Header: () => <strong>More</strong>,
      expander: true,
      width: 200,
      id: `expander`,
      style: {
        cursor: "pointer",
        fontSize: 25,
        padding: "0",
        textAlign: "center",
        userSelect: "none"
      },
      Expander: ({ isExpanded, ...rest }) => {
        let row = rest.row;
        let itemID = row._original.id;
        let index = row.index;
        return (
          <div data-item={itemID} data-field={expandField} data-index={index}>
            {isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}{" "}
          </div>
        );
      }
    });
  };

  buildTable = () => {
    let formFieldsArray = Object.keys(this.props.view.fields);
    this.columns = formFieldsArray.map(formFieldKey => {
      let formField = this.props.view.fields[formFieldKey];

      return {
        Header: this.t(formField.label || formFieldKey),
        accessor: formField.label,
        sortable: true,
        Cell: itemProp => {
          return this.buildCell(itemProp, formFieldKey, formField);
        }
      };
    });

    this.buildSelectColumn();

    if (this.isExpandable() && this.rootFilterEnabled()) {
      this.buildExpanders();
    }
    this.buildActionColumn();
  };

  isExpandable = () => {
    return this.props.view.expand_field;
  };

  getTdProps = (state, rowInfo, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        if (
          column.id !== "actions" &&
          column.id !== "selection" &&
          column.id !== "expander" &&
          !column.nested &&
          this.state.viewAction
        ) {
          this.handleRowClick(rowInfo);
        }
        if (column.id === "expander") {
          let expanded = this.state.expanded;
          expanded[rowInfo.viewIndex] = !expanded[rowInfo.viewIndex];
          this.setState({ expanded: expanded });
        }
      },
      style: {
        maxHeight: "100px",
        maxWidth: "60px"
      }
    };
  };

  /* finds the nested elements via fetch and stores them into an object */

  fillNestedRows = (indexData, label) => {
    var nestedRows = {};
    indexData.forEach(datum => {
      nestedRows[datum.id] = datum[label];
    });

    this.setState({ nestedRows: nestedRows });
  };

  customFetch = () => {
    let query = "";
    if (this.state.currentSort) {
      let sort = this.state.sortObj[this.state.currentSort];
      let columnId = this.state.currentSort;
      let params = this.state.params ? this.state.params : "";

      query += `&q[s]=${columnId}`;
      query += `+${sort}${params}`;
    }

    this.setState({ loading: true });
    request(
      {},
      `/${this.fixUrl()}?page=${this.state.page}&per_page=${
        this.state.per_page
      }${query}`,
      "index"
    ).then(response => {
      var responseRows = response.data[this.fixUrl()];
      if (this.rootFilterEnabled()) {
        responseRows = responseRows.filter(item => item.is_root);
      }
      responseRows = responseRows.map(item => {
        return { actions: item.id, ...item };
      });
      this.setState({ rows: responseRows, loading: false, fetching: true });
      this.fillNestedRows(responseRows, this.props.view.expand_field);
    });
  };

  t = this.props.t;

  rootFilterEnabled() {
    return this.state.useRootFilter;
  }

  fetchData = (prevState, instance) => {
    if (this.state.fetching) {
      this.setState({ fetching: false });
      return;
    }

    this.setState({ loading: true });

    let sort = this.state.sortObj[this.state.currentSort];
    let columnId = this.state.currentSort;
    let params = this.state.params ? this.state.params : "";
    let requestURL = "";

    requestURL = `/${this.fixUrl()}?page=${prevState.page}&per_page=${
      prevState.pageSize
    }&q[s]=${columnId}+${sort}${params}`;

    request({}, requestURL, "index").then(response => {
      var responseRows = response.data[this.fixUrl()];

      if (this.rootFilterEnabled()) {
        responseRows = response.data[this.fixUrl()].filter(
          item => item.is_root
        );
      }

      responseRows = responseRows.map(item => {
        return { actions: item.id, ...item };
      });
      this.setState({ rows: responseRows });
      this.setState({ loading: false });
      this.fillNestedRows(responseRows, this.props.view.expand_field);
    });
  };

  filterIcon() {
    let filterIcon = undefined;

    if (!this.rootFilterEnabled()) {
      filterIcon = (
        <span className="fa-stack fa-1x">
          <i className="fas fa-filter fa-stack-1x"></i>
          <i className="fas fa-ban fa-stack-1x" style={{ color: "Red" }}></i>
        </span>
      );
    } else {
      filterIcon = (
        <i
          className="fa fa-filter"
          aria-hidden="true"
          style={{ color: "Black" }}
        ></i>
      );
    }

    return filterIcon;
  }

  getFilterName = () => {
    if (this.rootFilterEnabled()) {
      return "Tree Filter";
    } else {
      return "Flat Filter";
    }
  };
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        {this.buildTable()}
        <div
          onClick={() => {
            let useRootFilter = this.state.useRootFilter;
            this.setState({ useRootFilter: !useRootFilter });
          }}
        >
          <strong> {this.getFilterName()} </strong> {this.filterIcon()}
        </div>
        <ReactTable
          manual
          sortable={false}
          nextText={t("Next")}
          expanded={this.state.expanded}
          previousText={t("Previous")}
          loadingText={t("Loading...")}
          noDataText={t("No rows found")}
          pageText={t("Page")}
          ofText={t("of")}
          rowsText={t("rows")}
          onSortedChange={this.changeSort}
          loading={this.state.loading}
          page={this.props.page}
          pageSize={this.props.per_page}
          pages={this.state.pages}
          onFetchData={this.fetchData}
          onPageSizeChange={this.pageSizeChange}
          getProps={this.getProps}
          getTdProps={this.getTdProps}
          data={this.state.rows}
          columns={this.columns}
          style={{ paddingBottom: "60px" }}
          SubComponent={row => {
            if (!this.isExpandable()) {
              return;
            }
            var cols = this.columns.map(col => {
              col.Header = () => {};
              return col;
            });

            let data = this.state.nestedRows[row.original.id] || [];
            return (
              <div stile={{ paddingLeft: "100px" }}>
                <ReactTable
                  columns={cols}
                  showPagination={false}
                  pageSize={data.length + 1}
                  data={data}
                ></ReactTable>
              </div>
            );
          }}
        />
        {this.state.openModal && this.state.item && (
          <ItemModal
            item={this.state.item.row._original}
            form={this.state.viewAction.form}
            url={this.fixUrl()}
            comments={this.state.viewAction.comments}
            closeModal={this.closeModal.bind(this)}
            commentsUrl={`${this.props.resource.resource}/${this.state.item.row._original.id}`}
          />
        )}
        {this.state.editModal ? (
          <EditModal
            id={this.state.editModalFields.id}
            url={this.fixUrl()}
            action={this.state.editModalAction}
            resource={this.props.resource}
            closeModal={this.closeEditModal}
            onLoad={this.customFetch}
            form={this.state.editModalFields.form}
          />
        ) : null}
        {this.props.state.filter.filters ? this.enableFilters() : null}
        {this.props.state.filter.reset ? this.resetFilters() : null}
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    filterOn: payload => {
      dispatch(addFilter(payload));
    },
    filterOff: () => {
      dispatch(removeFilter());
    },
    filterReset: () => {
      dispatch(resetFilter());
    }
  };
};
const mapStateToProps = state => {
  return {
    state: state
  };
};

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(TreeTable)
);
