
import {
    SEARCH_TRUE, SEARCH_FALSE
  } from '../actions/searchActions'

  function Search(state = {
      render: false,
      params: [],
      resource: ''
    }, action) {
     
    switch (action.type) {
      
      case SEARCH_TRUE:
        return{...state,
          render: true,
          params: action.params,
          resource: action.resource,
          form:action.form
         
        };
      case SEARCH_FALSE:
        return {
            render: false,
            params: [],
            resource: ''
         }
      default:
        return state
    }
  }
  
  export default Search
  