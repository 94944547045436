import React, { Component } from "react";
import request from "../utils/axios";

class TitanFileLink extends Component {
  constructor(props) {
    super(props);
    this.state = { url: "", displayName: "Not Fetched" };
    this.generateURL();
  }

  generateURL = () => {
    let res = this.props.resource.resource;
    let id = this.props.item.id;
    let field = this.props.field;
    if (id === "" || id === undefined) {
      return;
    }

    request({}, `/${res}/${id}/download/${field}`, "index")
      .then(response => {
        this.setState({ displayName: "Download File", url: response.url });
      })
      .catch(e => {
        this.setState({ displayName: "unavailable", url: "" });
      });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.item.id !== this.props.item.id) {
      this.generateURL();
    }
  }

  render() {
    return (
      <a href={this.state.url} target="_blank" rel="noopener noreferrer">
        {this.state.displayName}
      </a>
    );
  }
}

export default TitanFileLink;
