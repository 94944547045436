import { connect } from "react-redux"; //redux
import React from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-formio";
import {
  addFilter,
  removeFilter,
  resetFilter,
} from "../../store/actions/filteractions";
import { IsEmptyString } from "../../utils/strings";

class FilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.elementRef = React.createRef();
    let resourceName = this.props.location.pathname.split("/")[1];
    let resources = this.props.state.dashboard.resources;
    let resource = {};
    for (let i in resources) {
      if (resources[i].resource === resourceName) {
        resource = resources[i];
      }
    }
    this.state = {
      resource,
    };
  }
  onChange = (e) => {
    //this.checkReset(e);
  };

  checkReset = (e) => {
    let obj = Object.assign({}, e.data);
    // eslint-disable-next-line no-unused-vars
    var reset = true;
    for (var key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] !== "undefined") {
        if (obj[key].predicate !== "" || obj[key].needle !== "") {
          if (obj.hasOwnProperty(key) && typeof obj[key] !== "undefined") {
            if (obj[key].predicate !== "" || obj[key].needle !== "") {
              reset = false;
            }
          }
        }
      }
    }
  };
  onSubmission = (e) => {
    if (this.elementRef.current == null) {
      return;
    }
    if (e.data && this.formInstance.formio.checkValidity(e.data)) {
      let newState = {};
      let obj = Object.assign({}, e.data);
      delete obj.submit;
      delete obj.reset;
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (
            !IsEmptyString(obj[key].predicate) &&
            !IsEmptyString(obj[key].needle)
          ) {
            newState[key] = {};
            newState[key].predicate = obj[key].predicate;
            newState[key].needle = obj[key].needle;
          }

          if (!IsEmptyString(obj[key].bool)) {
            newState[key] = {};
            newState[key].bool = obj[key].bool;
          }
        }
      }

      this.setState({ filters: newState }, () => {
        let filters = Object.assign({}, this.state.filters);
        this.props.filterOn(filters);
      });
    } else {
    }
  };
  onApply = () => {};
  render() {
    return (
      <div ref={this.elementRef}>
        {this.state.resource.filters && (
          <Form
            ref={(form) => (this.formInstance = form)}
            onChange={(e) => {
              this.onChange(e);
            }}
            onCustomEvent={(e) => {
              this.onSubmission(e);
            }}
            form={this.state.resource.filters.form}
            options={{ i18n: this.props.state.dashboard.i18n, language: "it" }}
          />
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    filterOn: (payload) => {
      dispatch(addFilter(payload));
    },
    filterOff: () => {
      dispatch(removeFilter());
    },
    filterReset: () => {
      dispatch(resetFilter());
    },
  };
};
const mapStateToProps = (state) => {
  return {
    state: state,
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterBar)
);
