
import {
    ADD_FILTERS, REMOVE_FILTERS,RESET_FILTERS
} from '../actions/filteractions'

function Filter(state = {
}, action) {
    switch (action.type) {
        case RESET_FILTERS:
            return {
                ...state,
                reset:true
            }
        case ADD_FILTERS:
            return {
                filters:{...action.payload},
                reset:false
            };
        case REMOVE_FILTERS:
            return {}
        default:
            return state
    }
}

export default Filter;
