import AuthForm, {
  STATE_LOGIN,
  STATE_SIGNUP,
  STATE_RESET_PASSWORD_MAILER
} from "components/AuthForm";
import React from "react";
import { Card, Col, Row } from "reactstrap";

class AuthPage extends React.Component {
  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push("/login");
    } else if (authState === STATE_SIGNUP) {
      this.props.history.push("/signup");
    } else if (authState === STATE_RESET_PASSWORD_MAILER) {
      this.props.history.push("/reset_password_mailer");
    } else {
      this.props.history.push("/update_password_mailer");
    }
  };

  handleLogoClick = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <Row
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll"
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <AuthForm
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AuthPage;
