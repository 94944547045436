import React from 'react';
import DynamicForm from '../components/DynamicForm'

class ResourceActionsComponent extends React.Component {

 
  render() {
    return (
      <div>
        <DynamicForm actionType={this.props.action.type} url={`/${this.props.resource.resource}`} resource={this.props.resource} form={this.props.action.form} />
      </div>
    );
  }
}

export default ResourceActionsComponent;
