import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux"; //redux
import { Col } from "reactstrap";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { AuthCodeRequest } from "../utils/axios";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "utils/constants";

import {
  // MdCardGiftcard,
  MdLoyalty
} from "react-icons/lib/md";

import {
  fireNotification,
  disableNotification
} from "../store/actions/notificationActions";

class AcceptInvitationForm extends React.Component {
  constructor(props) {
    super(props);

    let pathname = this.props.location.pathname;
    let token = pathname.split("/")[2];
    console.log(pathname + " " + token);

    this.state = {
      authCode: token,
      passwordUpdated: false,
      unmatchingPasswords: false,
      shortPassword: false
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    if (this.newPassword.value.length < 6) {
      this.props.fireNotification({
        title: "",
        message: "Password troppo corta - minimo 6 caratteri",
        level: "success"
      });
      return;
    }

    if (this.newPassword.value === this.confirmNewPassword.value) {
      let data = {
        new_password: this.newPassword.value,
        auth_code: this.state.authCode
      };

      AuthCodeRequest(data, "/accept_invitation", "create").then(response => {
        this.setState({ passwordUpdated: true });
      });
    } else {
      this.props.fireNotification({
        title: "",
        message: "Le password non corrispondono",
        level: "success"
      });
    }
  };

  newFunc = () => {
    setTimeout(() => {
      if (!this.notificationSystem) {
        return;
      }
      if (this.props.state.notification.message) {
        let message = this.props.state.notification.message;

        this.notificationSystem.addNotification({
          title: <MdLoyalty />,
          message: message,
          level: "info"
        });
        this.props.disableNotification();
      }

      this.props.disableNotification();
    }, 500);

    return null;
  };

  render() {
    console.log("in the accept invitation form");
    return (
      <React.Fragment>
        {this.props.state.notification.notification ? this.newFunc() : null}

        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />

        <div className="row justify-content-center" ref={this.elementRef}>
          {this.state.passwordUpdated ? <Redirect to="/login" /> : null}
          <Col md={this.props.fullWidth ? 12 : 8}>
            <Form>
              <FormGroup>
                <Label for="New Password">New Password</Label>
                <Input
                  type="password"
                  innerRef={node => (this.newPassword = node)}
                />
                <Label for="Repeat New Password">Repeat New Password</Label>
                <Input
                  type="password"
                  innerRef={node => (this.confirmNewPassword = node)}
                />

                <hr />
                <Button
                  size="lg"
                  className="bg-gradient-theme-left border-0"
                  block
                  onClick={this.handleSubmit}
                >
                  Accept Invitation
                </Button>
              </FormGroup>
            </Form>
          </Col>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fireNotification: payload => {
      dispatch(fireNotification(payload));
    },
    disableNotification: () => {
      dispatch(disableNotification());
    }
  };
};

const mapStatetoProps = state => {
  return {
    state: state
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(AcceptInvitationForm);
