import React from "react";
import { asyncContainer, Typeahead } from "react-bootstrap-typeahead";
import request from "../utils/axios";
import DynamicForm from "./DynamicForm";
import { Redirect } from "react-router-dom";
import { withI18n } from "react-i18next";
const AsyncTypeahead = asyncContainer(Typeahead);

class SearchInput extends React.Component {
  state = {
    allowNew: false,
    isLoading: false,
    multiple: false,
    options: [],
    redirect: false
  };
  getUrl = query => {
    let param = this.props.params.map(word => {
      return `${word}_or_`;
    });
    param = param.join("");
    param = param.slice(0, param.length - 4);

    return `/${this.props.resource}/?q[${param}_cont]=${query}`;
  };
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {this.state.redirect ? (
          <Redirect to={`/${this.props.resource}/${this.state.id}/view`} />
        ) : null}
        <AsyncTypeahead
          placeholder={t("Search ...")}
          id="search-bar-id"
          isLoading={this.state.isLoading}
          onSearch={query => {
            this.setState({ isLoading: true });
            request({}, this.getUrl(query), "index").then(resp => {
              this.setState({
                isLoading: false,
                options: resp.data[this.props.resource]
              });
            });
          }}
          labelKey="id"
          filterBy={(option, props, index) => {
            return true;
          }}
          options={this.state.options}
          renderMenuItemChildren={(option, props, index) => (
            <div
              style={{ height: "auto" }}
              onClick={() => {
                this.setState({ redirect: true, id: option.id });
              }}
            >
              <DynamicForm
                minView={true}
                fullWidth={true}
                form={this.props.form}
                submission={option}
              />
            </div>
          )}
        />
      </React.Fragment>
    );
  }
  _handleSearch = query => {
    this.setState({ isLoading: true });
  };
}
export default withI18n()(SearchInput);
