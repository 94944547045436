import React from "react";
import DynamicForm from "./DynamicForm";
import { withI18n } from "react-i18next";
import {
  Modal,
  ModalBody,
  ListGroup,
  ListGroupItem,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import MapActions from "./MapActions";

class MapModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      modal: true
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps, modal: true });
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };
  toggleDropdown = id => {
    let stateObj = {};
    stateObj[id] = !this.state[id];
    this.setState({ ...stateObj });
  };
  handleItemClick = (item, coordinates) => {
    this.props.markerClick(item, coordinates);
  };
  handleAction = (action, item) => {
    this.setState({ openActions: true, actionData: action, actionItem: item });
  };
  returnDropdown = (id, item) => {
    let actions = this.props.actions;
    return (
      <ButtonDropdown
        isOpen={this.state[id]}
        toggle={() => {
          this.toggleDropdown(id);
        }}
      >
        <DropdownToggle color="deoco">Actions</DropdownToggle>
        <DropdownMenu>
          {actions.map((action, index) => {
            return (
              <DropdownItem
                onClick={() => {
                  this.handleAction(action, item);
                }}
                key={index}
              >
                {action.label}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </ButtonDropdown>
    );
  };
  renderCluster = () => {
    return (
      <ListGroup>
        {this.state.clusterData().map((item, index) => {
          return (
            <ListGroupItem key={index}>
              <span
                onClick={() => {
                  this.handleItemClick(item.props.item, item.props.coordinates);
                }}
              >
                {item.props.item[this.props.display_name]}
              </span>
              <div className="pull-right">
                {this.returnDropdown(item.props.item.id, item.props.item)}
              </div>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    );
  };
  renderMarker = () => {
    return (
      <DynamicForm
        form={this.props.viewAction.form}
        submission={this.props.markerData}
      />
    );
  };
  renderActions = () => {};
  closeAction = () => {
    this.setState({ openActions: false });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.openActions ? (
          <MapActions
            res={this.props.res}
            resource={this.props.resource}
            reload={this.props.reload}
            closeAction={this.closeAction}
            item={this.state.actionItem}
            action={this.state.actionData}
          />
        ) : null}
        <Modal
          modalTransition={{ timeout: 300 }}
          backdropTransition={{ timeout: 700 }}
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <div>
            <button
              type="button"
              onClick={this.toggle}
              className="close pull-right"
              style={{ padding: "15px" }}
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            {this.state.clusterData ? (
              <h5 style={{ margin: "20px" }}>
                {this.props.res.charAt(0).toUpperCase() +
                  this.props.res.slice(1)}
              </h5>
            ) : (
              <React.Fragment>
                <div
                  className="pull-left"
                  style={{ margin: "20px", fontSize: "18px" }}
                >
                  Item details
                </div>
                <div className="pull-right" style={{ margin: "20px" }}>
                  {" "}
                  {this.returnDropdown(1, this.props.markerData)}{" "}
                </div>
              </React.Fragment>
            )}
          </div>

          <ModalBody>
            {this.state.clusterData ? (
              this.renderCluster()
            ) : (
              <React.Fragment>{this.renderMarker()}</React.Fragment>
            )}
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}
export default withI18n()(MapModal);
