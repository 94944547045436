// import components
import React from "react";
import Kanban from "./pages/Kanban";
import Cards from "./pages/Cards";
import Calendar from "./pages/Calendar";
import Table from "./pages/Table";
import ItemShow from "./pages/ItemShow";
import ResourceActionsComponent from "./pages/ResourceActionsComponent";
import ItemActions from "./pages/ItemActions";
import Dashboard from "./pages/Dashboard";
import MapView from "./pages/MapView";
import ResetPassword from "./components/auth/ResetPassword";
import TreeTable from "./pages/TreeTable";
import Tree from "./pages/Tree";

export default resources => {
  let routeArray = [];
  let componentMap = {
    kanban: Kanban,
    cards: Cards,
    calendar: Calendar,
    table: Table,
    tree_table: TreeTable,
    tree: Tree,
    map: MapView
  };
  for (var i = 0; i < resources.length; i++) {
    let resource = resources[i];
    //views
    let viewRoutes = resource.views.map(view => {
      let ViewComponent = componentMap[view.type];
      return {
        path: `/${resource.resource}/${view.id}`,
        component: () => (
          <ViewComponent
            view={view}
            resource={resource}
            action="index"
            viewId={view.id}
          />
        ),
        auth_required: resource.auth_required
      };
    });

    routeArray = routeArray.concat(viewRoutes);

    //resource actions
    let resourceActionsRoutes = resource.resource_actions.map(action => {
      return {
        path: `/${resource.resource}/${action.id}`,
        component: () => (
          <ResourceActionsComponent resource={resource} action={action} />
        ),
        auth_required: resource.auth_required
      };
    });
    routeArray = routeArray.concat(resourceActionsRoutes);

    //item actions
    //decided to do another iteration for making the code as clear as possible
    let idArray = [];
    let itemActionsRoutes = resource.item_actions.map(action => {
      let route = {
        path: `/${resource.resource}/:uuid/${action.id}`,
        component: () => <ItemActions resource={resource} action={action} />,
        auth_required: resource.auth_required
      };
      if (idArray.indexOf(action.id) === -1) {
        idArray.push(action.id);
        return route; //DULICATE ROUTE Solution
      } else {
        return null;
      }
    });
    //itemActionsRoutes=itemActionsRoutes.flat()
    itemActionsRoutes = [].concat.apply([], itemActionsRoutes);
    itemActionsRoutes = itemActionsRoutes.filter(obj => obj);
    routeArray = routeArray.concat(itemActionsRoutes);

    //item show route
    let itemShow = {
      path: `/${resource.resource}/:uuid`,
      component: () => <ItemShow resource={resource.resource} />,
      auth_required: resource.auth_required
    };
    routeArray.push(itemShow);
  }
  let dashboard = {
    path: `/`,
    component: () => <Dashboard />,
    auth_required: true
  };
  routeArray.push(dashboard);
  let resetPassword = {
    path: `/resetPassword`,
    component: () => <ResetPassword />,
    auth_required: true
  };
  routeArray.push(resetPassword);

  return routeArray;
};
