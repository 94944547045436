//[[1,2],[1,2]]

export default (coordsArray) => {
    console.log(coordsArray)
    if (coordsArray.length === 1) {
        return [coordsArray[0], coordsArray[0]];
    }
    let init = coordsArray[0]
    let r = init[0], l = init[0], u = init[1], b = init[1];
    coordsArray.map((coord) => {
        if (coord[0] < l && coord[1] > u) {
            l = coord[0]
            u = coord[1]
        }
        if (coord[0] > r && coord[1] < b) {
            r = coord[0]
            b = coord[1]
        }
        return 0
    })
    return [[l,u],[r,b]]
}