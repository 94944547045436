import { Link } from "react-router-dom";
import React from "react";
import moment from "moment";
import Pluralize from "pluralize";
import TitanImage from "../components/TitanImage";
import TitanFileLink from "../components/TitanFileLink";

import getSymbolFromCurrency from "currency-symbol-map";

// rendertypes
let getPlural = (name) => {
  if (Pluralize.isPlural(name)) {
    return name;
  } else {
    return Pluralize.plural(name);
  }
};
let getLink = ({ resource, item, key, field }) => {
  let pluralKey = undefined;
  if (typeof item[key] !== "undefined") {
    if (field.link_key) {
      pluralKey = field.link_key;
    } else {
      pluralKey = getPlural(key);
    }

    return (
      <React.Fragment>
        {item[key] ? (
          item[key].map((arrObject, index) => {
            let text = arrObject[field.display_name];
            let url = `/${pluralKey}/${arrObject.id}/view`;
            return (
              <Link to={url} key={index}>
                {" "}
                {text}{" "}
              </Link>
            );
          })
        ) : (
          <div>not found</div>
        )}
      </React.Fragment>
    );
  }
  return "";
};
let getLocationProvider = (data) => {
  if (typeof data.item[data.key] !== "undefined") {
    switch (data.field.provider) {
      case "google":
        let location = data.item[data.key];
        let url = `https://maps.google.com/?q=${location.latitude},${location.longtitude}`;
        return (
          <a target="_blank" rel="noopener noreferrer" href={url}>
            Go to location
          </a>
        );
      default:
        return "could not find location";
    }
  } else {
    return "";
  }
};

//types helpers
let getArray = (data) => {
  if (typeof data.item[data.key] !== "undefined") {
    switch (data.field.render_as) {
      case "link":
        return getLink(data);
      default:
        let fullString = "";
        data.item[data.key].map((value) => {
          fullString += value + ",";
          return 0;
        });
        return fullString;
    }
  } else {
    return "";
  }
};
let getText = (data) => {
  if (typeof data.item[data.key] !== "undefined") {
    switch (data.field.render_as) {
      case "html":
        return (
          <div dangerouslySetInnerHTML={{ __html: data.item[data.key] }} />
        );
      default:
        return data.item[data.key];
    }
  } else {
    return "";
  }
};

let getBool = (data) => {
  let boolVal =
    data.item[data.key] === "undefined" ? false : data.item[data.key];
  switch (data.field.render_as) {
    case "checkbox":
      if (boolVal) {
        return <i className="fa fa-check" />;
      } else {
        return <i className="fa fa-times" />;
      }
    default:
      return data.item[data.key];
  }
};

let getFile = (data) => {
  if (typeof data.item[data.key] !== "undefined") {
    switch (data.field.render_as) {
      case "image":
        return (
          <TitanImage
            resource={data.resource}
            item={data.item}
            field={data.key}
          />
        );
      case "link":
        return (
          <TitanFileLink
            resource={data.resource}
            item={data.item}
            field={data.key}
          />
        );
      default:
        return "fileNotFound";
    }
  } else {
    return "";
  }
};
let getLocation = (data) => {
  switch (data.field.render_as) {
    case "link":
      return getLocationProvider(data);
    default:
  }
};
let getDate = ({ resource, item, key, field }) => {
  if (typeof item[key] !== "undefined") {
    if (item[key].date) {
      let startDate = moment.unix(item[key].date.start);
      let endDate = moment.unix(item[key].date.end);
      let formatType = field.format;
      let date = {};
      if (field.render_as === "only_start") {
        date = `${startDate.format(formatType)}`;
      } else if (field.render_as === "only_end") {
        date = `${endDate.format(formatType)}`;
      } else {
        date = `From ${startDate.format(formatType)} to ${endDate.format(
          formatType
        )}`;
      }
      return date;
    } else {
      return moment.unix(item[key]).format(field.format);
    }
  } else return "";
};
let getCurrency = ({ resource, item, key, field }) => {
  if (typeof item[key] !== "undefined") {
    let currency = null;
    if (field.render_as === "full_currency") {
      let symbol = getSymbolFromCurrency(item[key].currency.symbol);
      currency = `${symbol}${item[key].currency.amount}`;
    }
    if (field.render_as === "only_amount") {
      currency = item[key].currency.amount;
    }
    return currency;
  } else {
    return "";
  }
};
//types
export default (data) => {
  switch (data.field.type) {
    case "array":
      return getArray(data);
    case "text":
      return getText(data);
    case "file":
      return getFile(data);
    case "location":
      return getLocation(data);
    case "date":
      return getDate(data);
    case "currency":
      return getCurrency(data);
    case "boolean":
      return getBool(data);
    default:
      return data.item[data.key];
  }
};
