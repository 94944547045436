import AcceptInvitationForm from "components/AcceptInvitationForm";
import React from "react";
import { Card, Col, Row } from "reactstrap";

class AcceptInvitationPage extends React.Component {
  render() {
    console.log("In the accept invitation page");
    return (
      <Row
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll"
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <AcceptInvitationForm location={this.props.location} />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AcceptInvitationPage;
