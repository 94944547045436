import { getType, getSelectOptions } from "./formTypes";
import _ from "lodash";
export let replace = (server, create) => {
  server.map(sObj => {
    create = create || [];
    let testArr = create.filter(cObj => sObj.id === cObj.id);
    if (testArr.length === 0) {
      create.push({ id: sObj.id, _destroy: true });
    }
    return false;
  });
  return create;
};
export let mapRelationshipsOnUpdate = (server, create) => {
  let result = [];
  server = server.filter(x => !_.isNil(x));
  create = create.filter(x => !_.isNil(x));
  server = server.map(x => {
    return x.id;
  });

  let difference = server
    .filter(id => !create.includes(id))
    .map(id => {
      return { _unlink: true, id: id };
    });

  difference.forEach(toUnlink => result.push(toUnlink));
  create.forEach(toAdd => result.push(toAdd));
  return result;
};

export default (serverItem, original_submission, create, form) => {
  let sItem = Object.assign({}, serverItem);
  let cItem = Object.assign({}, create);

  for (var key in sItem) {
    if (sItem.hasOwnProperty(key)) {
      let type = getType(key, form);
      if (type === "nested") {
        cItem[key] = replace(sItem[key], cItem[key]);
      }
      if (type === "relationship") {
        cItem[key] = mapRelationshipsOnUpdate(sItem[key], cItem[key]);
      }
      if (type === "category") {
        let values = getSelectOptions(key, form);
        let selectedValue = original_submission[key];
        let categoryID = undefined;
        // if the selectbox is not changed, then the label is submitted
        // If one selects another value from the selectBox - then the uuid is submitted
        values.forEach(v => {
          if (v.label === selectedValue || v.value === selectedValue) {
            categoryID = v.value;
          }
        });

        if (_.isNil(categoryID)) {
          cItem[key] = [];
        } else {
          cItem[key] = [categoryID];
        }
      }
    }
  }
  return cItem;
};
