import React from "react";
import DynamicForm from "../DynamicForm";
import { withRouter } from "react-router-dom";
import request from "../../utils/axios";
import reverseFormTypes from "../../utils/reverseFormTypes";
import _ from "lodash";
import { withI18n } from "react-i18next";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CloneAction from "./CloneAction";
import BookkeepingAction from "./BookkeepingAction";

class ItemActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.initialize();
    this.renderByAction = this.renderByAction.bind(this);
  }

  initialize = () => {
    request({}, `/${this.props.url}/${this.props.id}`, "index").then(
      response => {
        var beforeReverse = _.cloneDeep(response);
        var obj = response;
        reverseFormTypes(obj, this.props.url, this.props.id).then(obj => {
          this.setState({ item: obj, beforeReverse: beforeReverse });
        });
      }
    );
  };

  renderByAction() {
    let closeModal = {
      modal: true,
      closeModal: this.props.closeModal,
      reload: this.props.reload
    };
    switch (this.props.action.id) {
      case "bookkeeping":
        return (
          <BookkeepingAction
            resource={this.props.resource}
            item_id={this.props.id}
            action={this.props.action}
            closeModal={closeModal}
          />
        );
      case "clone":
        return (
          <CloneAction
            closeModal={closeModal}
            url={`/${this.props.resource.resource}/${this.props.id}`}
            action={this.props.action}
          />
        );
      default:
        return (
          <DynamicForm
            form={this.props.form}
            submission={this.state.item}
            resource={this.props.resource}
            actionType={"update"}
            serverItem={
              this.state.beforeReverse ? this.state.beforeReverse : null
            }
            url={`/${this.props.resource.resource}/${this.props.id}`}
            closeModal={{
              modal: true,
              closeModal: this.props.closeModal,
              reload: this.props.reload
            }}
            reload={this.props.reload}
          />
        );
    }
  }
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {this.state.item ? (
          <Modal toggle={this.props.closeModal} isOpen={true} size="lg">
            <ModalHeader toggle={this.props.closeModal}>
              {" "}
              {t(`${this.props.action.label} item`)}
            </ModalHeader>
            <ModalBody>{this.renderByAction()}</ModalBody>{" "}
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withI18n()(withRouter(ItemActions));
