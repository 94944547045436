import React from 'react';
import { Redirect } from 'react-router-dom'
import request from '../../utils/axios'
import { Form } from "react-formio";
import { connect } from 'react-redux';//redux
import { logoutUser } from '../../store/actions/authActions'
import { Col } from 'reactstrap';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.elementRef = React.createRef();
  }
  onCustomEvent(props, e) {
    if (this.elementRef.current == null) {
      return;
    }
    delete e.data.submit;
    request(e.data, '/password', 'update').then((resp) => {
      this.props.logout();
      this.setState({ redirect: true });
    })
  }

  render() {
    return (
      <div className="row justify-content-center" ref={this.elementRef}>
        {this.state.redirect ? <Redirect to='/login' /> : null}
        <Col md={this.props.fullWidth ? 12 : 8}>
          <Form
            src={this.props.state.dashboard.authentication.change_password_form}
            onCustomEvent={e => this.onCustomEvent(this.props, e)}
          />
        </Col>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logoutUser())
    }
  }
}

const mapStatetoProps = (state) => {
  return ({
    state: state
  })
}

export default connect(mapStatetoProps, mapDispatchToProps)(ResetPassword);
