import React from "react";
import DynamicForm from "../components/DynamicForm";
import { withRouter } from "react-router-dom";
import request from "../utils/axios";
import reverseFormTypes from "../utils/reverseFormTypes";
import _ from "lodash";
import CloneAction from "../components/ItemActions/CloneAction";
import BookkeepingAction from "../components/ItemActions/BookkeepingAction";
class ItemActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: true
    };

    this.renderByAction = this.renderByAction.bind(this);
    this.initialize();
  }
  itemId = () => {
    let Id = this.props.location.pathname.split("/")[2];
    return Id;
  };
  initialize = () => {
    request(
      {},
      `/${this.props.resource.resource}/${this.itemId()}`,
      "index"
    ).then(response => {
      if (this.props.action.type === "update") {
        var beforeReverse = _.cloneDeep(response);
        var obj = response;
        reverseFormTypes(obj, this.props.resource.resource, this.itemId()).then(
          obj => {
            this.setState({ item: obj, beforeReverse: beforeReverse });
          }
        );
      } else {
        if (this.props.action.id === "view") {
        }
        this.setState({ item: response });
      }
    });
  };

  renderByAction() {
    switch (this.props.action.id) {
      case "clone":
        return (
          <CloneAction
            resource={this.props.resource}
            itemId={this.itemId()}
            url={`/${this.props.resource.resource}/${this.itemId()}`}
            action={this.props.action}
          />
        );
      case "bookkeeping":
        return (
          <BookkeepingAction
            resource={this.props.resource}
            itemId={this.itemId()}
            action={this.props.action}
          />
        );
      default:
        return (
          <DynamicForm
            form={this.props.action.form}
            submission={this.state.item}
            resource={this.props.resource}
            actionType={this.props.action.type}
            serverItem={
              this.state.beforeReverse ? this.state.beforeReverse : null
            }
            url={`/${this.props.resource.resource}/${this.itemId()}`}
            comments={this.props.action.comments}
          />
        );
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.item ? this.renderByAction() : null}
      </React.Fragment>
    );
  }
}

export default withRouter(ItemActions);
