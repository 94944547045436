import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { withI18n } from "react-i18next";
export default (loadMore)=>{

    class Toolbar extends React.Component {
        static propTypes = {
          view: PropTypes.string.isRequired,
          views: PropTypes.arrayOf(PropTypes.string).isRequired,
          label: PropTypes.node.isRequired,
          localizer: PropTypes.object,
          onNavigate: PropTypes.func.isRequired,
          onView: PropTypes.func.isRequired,
        }
      
        render() {
          const {t} = this.props
          let { localizer: { messages }, label } = this.props
      
          return (
            <div className="rbc-toolbar">
              <span className="rbc-btn-group">
                <button
                  type="button"
                  onClick={this.navigate.bind(null, navigate.TODAY)}
                >
                  {t(messages.today)}
                </button>
                <button
                  type="button"
                  onClick={this.navigate.bind(null, navigate.PREVIOUS)}
                >
                  {t(messages.previous)}
                </button>
                <button
                  type="button"
                  onClick={this.navigate.bind(null, navigate.NEXT)}
                >
                  {t(messages.next)}
                </button>
                
                <button className='btn btn-info'
                style={{
                    marginLeft:"20px",
                    background:"#286090",
                    color:'white'
                }}
                  type="button"
                  onClick={loadMore}
                >
                    {t('Load More')}
                </button>
              </span>
      
              <span className="rbc-toolbar-label">{label}</span>
      
              <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
            </div>
          )
        }
       t=this.props.t
        navigate = action => {
          this.props.onNavigate(action)
        }
      
        view = view => {
          this.props.onView(view)
        }
      
        viewNamesGroup(messages) {
          let viewNames = this.props.views
          const view = this.props.view
      
          if (viewNames.length > 1) {
            return viewNames.map(name => (
              <button
                type="button"
                key={name}
                className={cn({ 'rbc-active': view === name })}
                onClick={this.view.bind(null, name)}
              >
                {this.t(messages[name])}
              </button>
            ))
          }
        }
      }
      
    return withI18n()(Toolbar);
}

