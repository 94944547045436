import React, { Component } from "react";
import request from "../utils/axios";
//import { HandleRequest } from "./api_handler";
import Board from "react-trello";
import CustomCard from "../components/CustomCard";
import kanbanLanes from "../utils/kanbanLanes";
import params from "../utils/requestParams";
import {
  addFilter,
  removeFilter,
  resetFilter,
} from "../store/actions/filteractions";
import { withI18n } from "react-i18next";
import { connect } from "react-redux"; //redux
import { Button, Col } from "reactstrap";
class Kanban extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      board: {
        lanes: [],
      },
      page: 0,
      grouping: this.props.defaultGrouping,
    };
    this.initialize();
  }
  returnValue(item, grouping) {
    switch (grouping) {
      case "delivery_date":
        return item[grouping].date.start;
      default:
        return item[grouping];
    }
  } //helper functions

  fixUrl = () => {
    let resource = this.props.resource;
    return resource.source ? resource.source.url : resource.resource;
  };
  generateBoard(items, append) {
    let laneIndex = 0;
    let lanesId = 0;
    let mapping = []; //helpers
    let lanes = [];
    items.map((item, index) => {
      let grp = this.props.view.default_grouping;
      if (item[grp]) {
        let laneObj = kanbanLanes(item, grp);
        let possibleLane = laneObj.render; //get the grouping
        if (mapping.includes(possibleLane)) {
          //if the maping exists then just add the card
          laneIndex = mapping.indexOf(possibleLane); //get the index
          lanes[laneIndex].cards.push({
            id: item.id,
            viewAction: this.state.viewAction,
            newPageActions: this.state.newPageActions,
            customProps: {
              ...item,
            },
            resource: this.props.resource,
            fields: this.props.view.fields,
            view: this.props.view,
            reload: this.customFetch,
            url: this.fixUrl(),
            laneObj,
          });
        } else {
          //else create a new lane and register the mapping

          lanes.push({
            id: lanesId + "",
            title: (
              <div style={{ width: "130%" }} className="text-center">
                {laneObj.render}
              </div>
            ),
            laneObj,
            cards: [
              {
                id: item.id,
                key: index,
                customProps: {
                  ...item,
                },
                viewAction: this.state.viewAction,
                newPageActions: this.state.newPageActions,
                fields: this.props.view.fields,
                view: this.props.view,
                resource: this.props.resource,
                url: this.fixUrl(),
                reload: this.customFetch,
                laneObj,
              },
            ],
          });
          mapping.push(possibleLane);
          lanesId++;
        }
      }
      return 0;
    });
    if (append) {
      lanes = this.state.board.lanes.concat(lanes);
    }
    this.setState({
      board: {
        lanes,
      },
    });
  }
  handleDrag = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
    //api calls
    let newValue = this.state.board.lanes[targetLaneId].laneObj.obj;
    let payload = {};
    payload[this.props.view.default_grouping] = newValue;
    request(
      payload,
      `/${this.fixUrl()}/${cardId}`,
      "update"
    ).then((response) => {});
  };
  initialize() {
    let actions = this.props.view.view_actions.map((action) => {
      let actionThatMatch = this.props.resource.item_actions.filter(
        (resAction) => resAction.id === action
      );
      return actionThatMatch[0];
    });
    //add actions to the state
    let newPageActions = actions.filter((action) => action.id !== "view");
    let viewAction = actions.find((action) => action.id === "view");
    this.setState({
      newPageActions,
      viewAction,
    });
    this.customFetch(false);
  }
  customFetch = (append) => {
    let params = this.state.params ? this.state.params : "";
    let sort = this.props.view.default_sort
      ? `&q[s]=${this.props.view.default_sort}+asc`
      : "";
    request(
      {},
      `/${this.fixUrl()}?page=${this.state.page}${params}${sort}`,
      "index"
    ).then((response) => {
      this.generateBoard(response.data[this.fixUrl()], append); //make the resource UPPERCASE
    });
  };
  loadMore = () => {
    this.setState({ page: this.state.page + 1 }, () => {
      this.customFetch(true);
    });
  };
  render() {
    return (
      <React.Fragment>
        <div style={{ height: "calc(100vh - 145px)" }}>
          <Board
            data={this.state.board}
            customCardLayout
            draggable={true}
            cardDraggable={true}
            handleDragEnd={this.handleDrag}
          >
            <CustomCard />
          </Board>
        </div>
        <Col className="text-center">
          <Button
            color="deoco"
            onClick={() => {
              this.loadMore();
            }}
          >
            Load more
          </Button>
        </Col>
        {this.props.state.filter.filters ? this.enableFilters() : null}
        {this.props.state.filter.reset ? this.resetFilters() : null}
      </React.Fragment>
    );
  }
  resetFilters = () => {
    this.props.filterOff();
    this.setState({ params: undefined }, () => {
      this.customFetch(false);
    });
    return null;
  };
  enableFilters = () => {
    this.props.filterOff();
    let p = params(
      this.props.state.filter.filters,
      this.props.resource.filters.form
    );
    this.setState({ params: p, page: 0 }, () => {
      this.customFetch(false);
    });
    return null;
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    filterOn: (payload) => {
      dispatch(addFilter(payload));
    },
    filterOff: () => {
      dispatch(removeFilter());
    },
    filterReset: () => {
      dispatch(resetFilter());
    },
  };
};
const mapStateToProps = (state) => {
  return {
    state: state,
  };
};
export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(Kanban));
