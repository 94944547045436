import React from "react";
import request from "../utils/axios";
import SupportTicket from "./SupportTicket";
import moment from "moment-timezone";
import { Button, FormGroup, Col, Input } from "reactstrap";
class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = { comments: [] };
    this.initialize();
  }

  initialize = () => {
    this.loadComments();
  };

  loadComments = () => {
    request({}, `${this.props.url}/comments`, "index").then(response => {
      this.setState({ comments: response.comments });
    });
  };

  comment = e => {
    e.preventDefault();
    this.setState({ body: e.target.value });
  };

  addComment = () => {
    request(
      { body: this.state.body },
      `${this.props.url}/comments`,
      "create"
    ).then(() => {
      this.loadComments();
      this.setState({ body: "" });
    });
  };
  render() {
    return (
      <React.Fragment>
        <br></br>
        <h5> Comments</h5>
        <hr></hr>
        {this.state.comments.length > 0
          ? this.state.comments.map((comment, index) => {
              return (
                <SupportTicket
                  key={index}
                  text={comment.body}
                  name={comment.user_id}
                  date={moment(comment.created_at).fromNow()}
                />
              );
            })
          : "There are no comments yet"}
        <hr></hr>
        <FormGroup row>
          <Col sm={10}>
            <Input
              type="textarea"
              value={this.state.body}
              onChange={e => this.comment(e)}
              name="text"
              id="exampleText"
            />
          </Col>
        </FormGroup>
        <Button
          color="deoco"
          onClick={() => {
            this.addComment();
          }}
        >
          Add comment
        </Button>
      </React.Fragment>
    );
  }
}

export default Comments;
//itemId
//resource
