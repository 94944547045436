import sidebarBgImage from 'assets/img/sidebar/trianglify.png';
import SourceLink from 'components/SourceLink';
import getIcon from '../../utils/getIcons'
import React from 'react';
import { withI18n } from "react-i18next";
import {

  MdKeyboardArrowDown
} from 'react-icons/lib/md';//icons
import { NavLink,Link } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';//reactstrap
import { connect } from 'react-redux';//redux
import bn from 'utils/bemnames';//styling

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};
const arrayToObject = (array) => //helper
  array.reduce((obj, item) => {
    obj[item.name] = false
    return obj
  }, {})
const bem = bn.create('sidebar'); //styling

class Sidebar extends React.Component {
  state = arrayToObject(this.props.state.resources.map((resource) => {
    return {   //dynamically set all state for resources as isOpenResource:false
      name: `isOpen${resource.resource}`
    }
  }))

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];
      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  returnResources = (() =>
    this.props.state.resources.map((resource, index) => {
      return {
        name: resource.display_name || resource.resource,
        index,
        icon: resource.icon,
        singleView: resource.views.length > 1 ? false : true,
        to: `${resource.source ? resource.source.url : '/' + resource.resource}/${resource.views[0] ? resource.views[0].id : ''}`,

      }
    })
  )

  returnViewsAndActions = index => {
    let resource = this.props.state.resources[index]
    let viewArray = resource.views.map(view => {
      return {
        to: `${resource.source ? resource.source.url : '/' + resource.resource}/${view.id}`,
        name: view.label,
        exact: true,
        Icon: getIcon(view)
      }
    })


    return viewArray
  }


  render() {
    const {t} = this.props
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              {/* <img
                src={logo200Image}
                width="40"
                height="30"
                className="pr-2"
                alt=""
              /> */}
              <div  >
                {t(this.props.state.title) }
              </div>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {
              this.returnResources().map(({ name, icon, index: ResourceIndex, singleView, to }, index) => {
                return (
                  <React.Fragment key={index}>
                    <NavItem
                      className={bem.e('nav-item')}
                      onClick={this.handleClick(name)}>
                      {!singleView ?(
                        <BSNavLink 
                          className={bem.e('nav-item-collapse')}
                        >
                          <div className="text-uppercase">
                            <i className={icon}></i>
                            <span style={{ paddingLeft: '10px' }} >{t(name)}</span>
                          </div>
                          {!singleView ? <MdKeyboardArrowDown
                            className={bem.e('nav-item-icon')}
                            style={{
                              padding: 0,
                              transform: this.state[`isOpen${name}`]
                                ? 'rotate(0deg)'
                                : 'rotate(-90deg)',
                              transitionDuration: '0.3s',
                              transitionProperty: 'transform',
                            }}
                          /> : null}
                        </BSNavLink>):(<BSNavLink 
                          className={bem.e('nav-item-collapse')}
                          to={to}
                          tag={Link}
                        >
                          <div className="text-uppercase">
                            <i className={icon}></i>
                            <span style={{ paddingLeft: '10px' }} >{t(name)}</span>
                          </div>
                          {!singleView ? <MdKeyboardArrowDown
                            className={bem.e('nav-item-icon')}
                            style={{
                              padding: 0,
                              transform: this.state[`isOpen${name}`]
                                ? 'rotate(0deg)'
                                : 'rotate(-90deg)',
                              transitionDuration: '0.3s',
                              transitionProperty: 'transform',
                            }}
                          /> : null}
                        </BSNavLink>)}
                    </NavItem>

                    {!singleView ? (<Collapse isOpen={this.state[`isOpen${name}`]}>
                      {this.returnViewsAndActions(ResourceIndex).map(({ to, name, exact, Icon }, index) => (
                        <NavItem key={index} className={bem.e('nav-item')}>
                          <BSNavLink
                            id={`navItem-${name}-${index}`}
                            className="text-uppercase"
                            tag={NavLink}
                            to={to}
                            activeClassName="active"
                            exact={exact}>
                            <i className={Icon}></i>
                            <span style={{ paddingLeft: '10px' }} >{t(name)}</span>
                          </BSNavLink>
                        </NavItem>
                      ))}
                    </Collapse>) : null}
                  </React.Fragment>
                )
              })
            }

          </Nav>
        </div>
      </aside>
    );
  }
}
const mapStateToProps = (state) => {
  return ({
    state: state.dashboard
  })
}
export default withI18n()(connect(mapStateToProps)(Sidebar))
