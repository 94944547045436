import PropTypes from "prop-types";
import { connect } from "react-redux";
import React from "react";
import { Form as FormioForm } from "react-formio";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "utils/constants";
import logo200Image from "assets/img/logo/logo_200.png";
import { loginUser } from "../store/actions/authActions";
import { Redirect } from "react-router-dom";
import { withI18n } from "react-i18next";
import {
  fireNotification,
  disableNotification
} from "../store/actions/notificationActions";
import request from "../utils/axios";
import {
  // MdCardGiftcard,
  MdLoyalty
} from "react-icons/lib/md";

class AuthForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      redirect: this.props.state.authentication.isAuthenticated
    };

    this.elementRef = React.createRef();
  }
  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();
    this.props.onChangeAuthState(authState);
  };

  signup = e => {
    if (this.elementRef.current == null) {
      return;
    }
    if (e.data && this.formInstance.formio.checkValidity(e.data, true)) {
      let obj = Object.assign({}, e.data);
      delete obj.submit;
      delete obj.confirm_password;
      request(obj, "/users", "create").then(response => {
        this.props.login(response, true);
      });
    } else {
      this.props.fireNotification({
        title: "",
        message: "Please fix validation error",
        level: "success"
      });
    }
  };
  handleSubmit = event => {
    event.preventDefault();
    if (this.isLogin) {
      this.props.login({
        username: this.username.value,
        password: this.password.value,
        role: this.props.state.dashboard.authentication.role
      });
    }
    if (this.isPasswordReset) {
      this.props.passwordReset({
        email: this.elamil.value
      });
    }
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return "Login";
    }

    if (!buttonText && this.isSignup) {
      return "Signup";
    }

    return buttonText;
  }
  newFunc = () => {
    setTimeout(() => {
      if (!this.notificationSystem) {
        return;
      }
      if (this.props.state.notification.message) {
        this.notificationSystem.addNotification({
          title: <MdLoyalty />,
          message: "incorrect username or password",
          level: "info"
        });
        this.props.disableNotification();
      }

      this.props.disableNotification();
    }, 500);

    return null;
  };
  handleLoginKeyUp = e => {
    if (e.keyCode === 13) {
      if (this.isLogin) {
        this.props.login({
          username: this.username.value,
          password: this.password.value,
          role: this.props.state.dashboard.authentication.role
        });
      }
    }
  };

  render() {
    const { t } = this.props;
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      children,
      onLogoClick
    } = this.props;

    return (
      <React.Fragment>
        {this.props.state.notification.notification ? this.newFunc() : null}
        {this.props.state.authentication.isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <React.Fragment />
        )}
        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
        <Form onSubmit={this.handleSubmit}>
          {showLogo && (
            <div className="text-center pb-4">
              <img
                src={
                  this.props.state.dashboard.logo_url
                    ? this.props.state.dashboard.logo_url
                    : logo200Image
                }
                className="rounded"
                style={{ width: 120, height: 120, cursor: "pointer" }}
                alt="logo"
                onClick={onLogoClick}
              />
            </div>
          )}
          {!this.isSignup ? (
            <React.Fragment>
              <FormGroup>
                <Label for={usernameLabel}>{t(usernameLabel)}</Label>
                <Input
                  innerRef={node => (this.username = node)}
                  {...usernameInputProps}
                />
              </FormGroup>
              <FormGroup>
                <Label for={passwordLabel}>{t(passwordLabel)}</Label>
                <Input
                  onKeyUp={e => {
                    this.handleLoginKeyUp(e);
                  }}
                  innerRef={node => (this.password = node)}
                  {...passwordInputProps}
                />
              </FormGroup>
            </React.Fragment>
          ) : (
            <div ref={this.elementRef}>
              <FormioForm
                ref={form => (this.formInstance = form)}
                src={this.props.state.dashboard.authentication.signup_form}
                onCustomEvent={e => {
                  this.signup(e);
                }}
              />
              or <br />
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                {t("Login")}
              </a>
              <hr />
            </div>
          )}

          {this.isSignup ? null : (
            <React.Fragment>
              <Button
                size="lg"
                className="bg-gradient-theme-left border-0"
                block
                onClick={this.handleSubmit}
              >
                {t(this.renderButtonText())}
              </Button>

              <React.Fragment>
                <a
                  href="#reset_password_mailer"
                  onClick={this.changeAuthState(STATE_RESET_PASSWORD_MAILER)}
                >
                  {t("Forgot Your Password?")}
                </a>
              </React.Fragment>

              <div className="text-center pt-1">
                {this.isSignup ? (
                  <h6>
                    {" "}
                    <a
                      href="#login"
                      onClick={this.changeAuthState(STATE_LOGIN)}
                    >
                      {t("Login")}
                    </a>
                  </h6>
                ) : this.props.state.dashboard.authentication.signup_form ? (
                  <React.Fragment>
                    <h6>{t("or")}</h6>
                    <a
                      href="#signup"
                      onClick={this.changeAuthState(STATE_SIGNUP)}
                    >
                      {t("Signup")}
                    </a>
                  </React.Fragment>
                ) : null}
              </div>
            </React.Fragment>
          )}
          {children}
        </Form>
      </React.Fragment>
    );
  }
}

export const STATE_LOGIN = "LOGIN";
export const STATE_SIGNUP = "SIGNUP";
export const STATE_RESET_PASSWORD_MAILER = "RESET_PASSWORD_MAILER";
export const STATE_UPDATE_PASSWORD_MAILER = "UPDATE_PASSWORD_MAILER";
export const STATE_ACCEPT_INVITATION = "ACCEPT_INVITATION";

AuthForm.propTypes = {
  authState: PropTypes.oneOf([
    STATE_LOGIN,
    STATE_SIGNUP,
    STATE_RESET_PASSWORD_MAILER,
    STATE_UPDATE_PASSWORD_MAILER,
    STATE_ACCEPT_INVITATION
  ]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func
};
AuthForm.defaultProps = {
  authState: "LOGIN",
  showLogo: true,
  usernameLabel: "Email",
  usernameInputProps: {
    type: "email",
    placeholder: "your@email.com"
  },
  passwordLabel: "Password",
  passwordInputProps: {
    type: "password",
    placeholder: "your password"
  },
  confirmPasswordLabel: "Confirm Password",
  confirmPasswordInputProps: {
    type: "password",
    placeholder: "confirm your password"
  },
  onLogoClick: () => {}
};

const mapStateToProps = state => {
  return {
    state: state
  };
};
const mapDispatchToProps = dispatch => {
  return {
    login: (user, isSignup) => {
      dispatch(loginUser(user, isSignup));
    },
    fireNotification: payload => {
      dispatch(fireNotification(payload));
    },
    disableNotification: () => {
      dispatch(disableNotification());
    }
  };
};

export default withI18n()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthForm)
);
