/* global google */
import TextFieldComponent from "formiojs/components/textfield/TextField";
import Formio from "formiojs/Formio";

export default class MapComponent extends TextFieldComponent {
  static schema(...extend) {
    return TextFieldComponent.schema(
      {
        type: "map",
        label: "Map",
        key: "map",
        map: {
          key: "",
          region: "",
          gmapId: "",
          autocompleteOptions: {}
        }
      },
      ...extend
    );
  }
  constructor(component, options, data) {
    super(component, options, data);

    this.dataValue = this.emptyValue;
  }

  init() {
    super.init();
    // Get the source for Google Maps API
    let src =
      "https://maps.googleapis.com/maps/api/js?v=3&libraries=places&callback=googleMapsCallback";
    if (this.component.map && this.component.map.key) {
      src += `&key=${this.component.map.key}`;
    }
    if (this.component.map && this.component.map.region) {
      src += `&region=${this.component.map.region}`;
    }
    Formio.requireLibrary("googleMaps", "google.maps.places", src);
  }

  get defaultSchema() {
    return MapComponent.schema();
  }

  get emptyValue() {
    return {
      location: {
        address: "",
        latitude: 42.5,
        longitude: 12.5,
        radius: 1
      }
    };
  }

  formatValue(value) {
    if (typeof value === "string") {
      return value;
    }
    return value.location.address;
  }

  setValue(value) {
    if (typeof value === "string") {
      super.setValue(value);
    } else {
      super.setValue(value.location.address);
      this.dataValue = value;
    }
  }

  renderElement(value, index) {
    return (
      super.renderElement(value, index) +
      this.renderTemplate("map", {
        mapId: this.component.map.gmapId
      })
    );
  }

  attach(element) {
    const ret = super.attach(element);
    this.loadRefs(element, { gmapElement: "multiple" });
    return ret;
  }

  onChange(flags, fromRoot) {
    super.onChange();
    if (!this.marker || !this.gmap) {
      return;
    } else {
      console.log("Setting marker position");
      let lat = this.dataValue.location.latitude;
      let lng = this.dataValue.location.longitude;
      let latLng = { lat: lat, lng: lng };
      this.marker.setPosition(latLng);
      this.gmap.setCenter(latLng);
    }
  }

  attachElement(element, index) {
    super.attachElement(element, index);
    Formio.libraryReady("googleMaps").then(() => {
      const defaultLatlng = new google.maps.LatLng(
        this.dataValue.location.latitude,
        this.dataValue.location.longitude
      );
      console.log(this.dataValue);
      const options = {
        zoom: 10,
        center: defaultLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off"
              }
            ]
          }
        ]
      };

      const mapElement = document.getElementById(this.component.map.gmapId);
      if (!mapElement) {
        return;
      }

      element.map = new google.maps.Map(mapElement, options);
      this.addMarker(defaultLatlng, "Default Marker", element);

      let autocompleteOptions = {};
      if (this.component.map) {
        autocompleteOptions = this.component.map.autocompleteOptions || {};
      }
      const autocomplete = new google.maps.places.Autocomplete(
        element,
        autocompleteOptions
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          console.log("Autocomplete's returned place contains no geometry");
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          element.map.fitBounds(place.geometry.viewport);
        } else {
          element.map.setCenter(place.geometry.location);
          element.map.setZoom(17); // Why 17? Because it looks good.
        }
        element.marker.setIcon(
          /** @type {google.maps.Icon} */ ({
            url: place.icon,
            size: new google.maps.Size(71, 71),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(17, 34),
            scaledSize: new google.maps.Size(35, 35)
          })
        );
        element.marker.setPosition(place.geometry.location);
        let location = {
          location: {
            address: place.formatted_address,
            latitude: parseFloat(place.geometry.location.lat()),
            longitude: parseFloat(place.geometry.location.lng()),
            radius: 1
          }
        };
        this.setValue(location);
      });
    });
  }

  addMarker(latlng, title, element) {
    element.marker = new google.maps.Marker({
      position: latlng,
      map: element.map,
      title: title,
      draggable: true
    });

    this.marker = element.marker;
    this.gmap = element.map;
    element.marker.addListener("dragend", event => {
      const geocoder = new google.maps.Geocoder();
      const latlng = {
        lat: parseFloat(event.latLng.lat()),
        lng: parseFloat(event.latLng.lng())
      };
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            let location = {
              location: {
                address: results[0].formatted_address,
                latitude: latlng.lat,
                longitude: latlng.lng,
                radius: 1
              }
            };
            this.setValue(location);
          } else {
            console.log("No results found");
          }
        } else {
          console.log(`Geocoder failed due to: ${status}`);
        }
      });
    });
  }
}
