import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

export default (resources) => {
  i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
      resources: {},
      lng: localStorage.getItem('lang'),
      keySeparator: false, // we do not use keys in form messages.welcome
      debug: false,
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
  for (var key in resources) {
    if (resources.hasOwnProperty(key)) {
      i18n.addResourceBundle(key, 'translation', resources[key], true, true);
    }
  }
  return i18n
}

