import UpdatePasswordMailerForm from "components/UpdatePasswordMailerForm";
import React from "react";
import { Card, Col, Row } from "reactstrap";

class UpdatePasswordMailerPage extends React.Component {
  render() {
    return (
      <Row
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll"
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <UpdatePasswordMailerForm location={this.props.location} />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default UpdatePasswordMailerPage;
