import React, { PureComponent } from "react";
import request from "../utils/axios";
import { includes } from "lodash";
import { Treebeard, decorators } from "react-treebeard";
import ItemModal from "../components/ItemActions/ItemModal";
import defaultTheme from "react-treebeard/dist/themes/default";
import { connect } from "react-redux"; //reduxu
import { withI18n } from "react-i18next";

export class Tree extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      openModal: false,
      hasMore: false,
      currentPage: 0,
      dataSet: false
    };
    this.onToggle = this.onToggle.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.responseRows = [];
    this.buildData();
  }

  viewAction = () => {
    let actions = this.props.view.view_actions.map(action => {
      let actionThatMatch = this.props.resource.item_actions.filter(
        resAction => resAction.id === action
      );
      return actionThatMatch[0];
    });

    let viewAction = actions.find(action => action.id === "view");
    return viewAction;
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasMore) {
      this.buildData();
    } else if (!this.state.dataSet) {
      this.setData();
    }
  }
  dropDownActions = () => {
    let actions = this.props.view.view_actions.map(action => {
      let actionThatMatch = this.props.resource.item_actions.filter(
        resAction => resAction.id === action
      );
      return actionThatMatch[0];
    });

    let dropDownActions = actions.filter(action => action.id !== "view");
    return dropDownActions;
  };

  setData = () => {
    let resourceName = this.props.view.resource;
    let nameKey = this.props.view.node_label;
    let nestedAttribute = this.props.view.nested_attribute;
    let responseRows = this.responseRows;
    responseRows = responseRows.filter(item => item.is_root);
    let roots = responseRows.map(root => {
      let children = root[nestedAttribute] || [];
      children = children.map(child => {
        return { name: child[nameKey], toggled: false, ...child };
      });
      return {
        name: root[nameKey],
        toggled: false,
        children: children,
        ...root
      };
    });

    let data = {
      name: resourceName,
      toggled: true,
      children: roots
    };

    console.log("building data...");
    this.setState({ data: data, openModal: false, dataSet: true });
  };
  buildData = () => {
    let resourceName = this.props.view.resource;
    request(
      {},
      `/${resourceName}?page=${this.state.currentPage}`,
      "index"
    ).then(response => {
      this.responseRows = this.responseRows.concat(response.data[resourceName]);
      let hasMore = response.metadata.count === response.metadata.per_page;
      this.setState({
        hasMore: hasMore,
        currentPage: this.state.currentPage + 1
      });
    });
  };

  fixUrl = () => {
    let resource = this.props.resource;
    return resource.source ? resource.source.url : resource.resource;
  };

  handleRowClick = () => {
    this.setState({
      openModal: true
    });
  };

  closeModal = () => {
    this.setState({
      openModal: false
    });
  };

  submittableNode = node => {
    let newNode = Object.assign({}, node);
    newNode["actions"] = node.id;
    delete newNode["children"];
    delete newNode["active"];
    delete newNode["toggled"];

    return newNode;
  };
  handleClick(e) {
    let tagName = e.target.tagName;
    let innerText = e.target.innerHTML;
    // if not clicking on the arrow and not click on the resource name (root)
    if (
      tagName !== "polygon" &&
      tagName !== "svg" &&
      innerText !== this.props.view.resource
    ) {
      this.handleRowClick();
    }
  }
  onToggle(node, toggled) {
    const { cursor, data } = this.state;

    if (cursor) {
      this.setState(() => ({ cursor, active: false }));
    }

    node.active = false;
    if (node.children) {
      node.toggled = toggled;
    }

    this.setState(() => ({ cursor: node, data: Object.assign({}, data) }));
  }

  onSelect(node) {
    const { cursor, data } = this.state;

    if (cursor) {
      this.setState(() => ({ cursor, active: false }));
      if (!includes(cursor.children, node)) {
        cursor.toggled = false;
        cursor.selected = false;
      }
    }

    node.selected = true;

    this.setState(() => ({ cursor: node, data: Object.assign({}, data) }));
  }

  render() {
    const { data } = this.state;
    defaultTheme.tree.base = {
      ...defaultTheme.tree.base,
      color: "black",
      backgroundColor: "f8f9fa",
      fontSize: "1.2em",
      fontWeight: "400",
      paddingTop: "10px",
      paddingLeft: "10px"
    };
    defaultTheme.tree.node.header.base = {
      ...defaultTheme.tree.node.header.base,
      color: "black"
    };
    defaultTheme.tree.node.toggle.arrow = {
      ...defaultTheme.tree.node.toggle.arrow,
      fill: "#9DA5AB"
    };
    defaultTheme.tree.node.toggle.wrapper = {
      ...defaultTheme.tree.node.toggle.wrapper,
      margin: "-14px 0 0 -7px"
    };
    defaultTheme.tree.node.subtree = {
      ...defaultTheme.tree.node.subtree,
      paddingLeft: "40"
    };

    return (
      <React.Fragment>
        <div
          style={{ overflow: "scroll", maxHeight: "calc(100vh - 140px)" }}
          onClick={e => this.handleClick(e)}
        >
          <Treebeard
            data={data}
            onToggle={this.onToggle}
            onSelect={this.onSelect}
            decorators={decorators}
            style={defaultTheme}
          />
        </div>
        {this.state.openModal && this.state.cursor && (
          <div>
            <ItemModal
              item={this.submittableNode(this.state.cursor)}
              form={this.viewAction().form}
              actions={this.dropDownActions()}
              url={this.fixUrl()}
              refetch={this.buildData}
              comments={this.viewAction().comments}
              closeModal={this.closeModal.bind(this)}
              commentsUrl={`${this.fixUrl()}/${this.state.cursor.id}`}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {};
};
const mapStateToProps = state => {
  return {
    state: state
  };
};

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(Tree));
