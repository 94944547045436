import React from "react";
import { withRouter } from "react-router-dom";
import request from "../../utils/axios";
import { connect } from "react-redux"; //redux
import {
  fireNotification,
  disableNotification
} from "../../store/actions/notificationActions";
import { Redirect } from "react-router";
import _ from "lodash";
import { submitBehaivour } from "../../utils/formTypes";
import { withI18n } from "react-i18next";
import { Col, Button, Input, ButtonGroup } from "reactstrap";

class BookkeepingAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rerender: false,
      loading: false,
      amount: 0,
      currency: this.props.action.currency,
      bookkeeping_type: this.props.action.bookkeeping_type
    };

    this.setAmount = this.setAmount.bind(this);
    this.initialize();
  }
  initialize = () => {};

  setAmount = event => {
    let val = event.target.value;
    let amount = 0;
    if (val !== "" && !_.isNull(val)) {
      amount = parseInt(event.target.value);
      if (amount < 0) {
        amount = 0;
      }
      this.setState({ amount: amount });
    }
  };

  createEntry = type => {
    let submitObj = {
      item_id: this.props.item_id,
      currency: this.state.currency,
      type: type,
      amount: this.state.amount
    };

    this.setState({ loading: true });
    request(submitObj, `/one_shot_bookkeeping_entry`, "create").then(
      response => {
        this.setState({ loading: false });
        if (!this.props.closeModal) {
          if (this.props.action.after_success) {
            this.setState(
              submitBehaivour(
                this.props.resource,
                this.props.itemId,
                this.props.action.after_success,
                this.state.rerender
              )
            );
          }
        } else {
          this.props.closeModal.closeModal();
          this.props.closeModal.reload();
        }
        this.props.fireNotification({
          title: "",
          message: this.t("credits Added"),
          level: "success"
        });
      }
    );
  };
  t = this.props.t;
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {this.state.to ? <Redirect to={this.state.to} /> : null}
        {this.state.loading ? (
          <i
            class="fa fa-circle-o-notch fa-spin"
            style={{
              "font-size": "54px",
              marginLeft: "50%",
              marginBottom: "50px"
            }}
          ></i>
        ) : (
          <React.Fragment>
          <div className="row justify-content-center">
            <Col md={8}>
              <label for="amount_input"> Amount</label>
              <Input type="number" id="amount_input" onChange={this.setAmount} />
              <br/>
              <br/>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    this.createEntry("credit");
                  }}
                  color="warning"
                >
                  {t("Aggiungi")}
                </Button>
            
                <Button
                  onClick={() => {
                    this.createEntry("debit");
                  }}
                  color="danger"
                >
                  {t("Rimuovi")}
                </Button>
            </ButtonGroup>
            </Col>
          </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fireNotification: payload => {
      dispatch(fireNotification(payload));
    },
    disableNotification: () => {
      dispatch(disableNotification());
    }
  };
};

const mapStatetoProps = state => {
  return {
    state: state
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withI18n()(withRouter(BookkeepingAction)));
