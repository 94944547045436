import moment from "moment";
let getDate = (obj, key) => {
  let date = obj[key];
  obj[`${key}_start`] = moment.unix(date.date.start).format();
  obj[`${key}_end`] = moment.unix(date.date.end).format();
  return obj;
};
let getCurrency = (obj, key) => {
  let currency = obj[key];
  obj[key] = currency.currency.amount;
  return obj;
};
let getFile = async (obj, key, res, id) => {
  let oldFile = Object.assign({}, obj[key]);
  let newObj = Object.assign({}, obj);
  newObj[key] = [];
  let newFile = {};
  newFile.storage = "s3";
  newFile.name = oldFile.file.filename;
  newFile.size = oldFile.file.size;

  // let url = await request({}, `/${res}/${id}/download/${key}`, 'index')
  //newFile.url = `https://static-assets-store.s3.eu-central-1.amazonaws.com/${newFile.name}`;
  newObj[key].push(newFile);
  return newObj;
};

let getNested = async (obj, key, res, id) => {
  for (let i = 0; i < obj[key].length; i++) {
    let newObj = obj[key][i];

    // if the array is a category
    if (typeof newObj === "string") {
      continue;
    }
    for (var nKey in newObj) {
      if (
        newObj.hasOwnProperty(nKey) &&
        typeof newObj[nKey] !== "undefined" &&
        newObj[nKey] !== null
      ) {
        if (newObj[nKey].date) {
          newObj = getDate(newObj, nKey);
        }
        if (newObj[nKey].currency) {
          newObj = getCurrency(newObj, nKey);
        }
        if (newObj[nKey].file) {
          newObj = await getFile(newObj, nKey, res, id);
        }
        if (nKey === "created_at" || nKey === "updated_at") {
          let deleteObj = Object.assign({}, newObj);
          delete deleteObj[nKey];
          newObj = deleteObj;
        }
      }
    }
    obj[key][i] = newObj;
  }
  return obj;
};
export default async (obj, res, id) => {
  let newObj = Object.assign({}, obj);

  for (var key in newObj) {
    if (newObj.hasOwnProperty(key) && typeof newObj[key] !== "undefined") {
      if (newObj[key]) {
        if (newObj[key].date) {
          newObj = getDate(newObj, key);
        }
        if (newObj[key].currency) {
          newObj = getCurrency(newObj, key);
        }
        if (newObj[key].file) {
          newObj = await getFile(newObj, key, res, id);
        }
        if (Array.isArray(newObj[key])) {
          newObj = await getNested(newObj, key, res, id);
        }
      }
    }
  }
  return newObj;
};
