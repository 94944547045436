
import React from 'react';
import { Redirect,Route } from 'react-router'
import { connect } from 'react-redux';
import {refreshToken} from '../../store/actions/authActions'
class ProtectedRoute extends React.Component {
  
  render(){
      let { component: Component,layout:Layout, ...rest } = this.props
      let expireDate = new Date(localStorage.getItem('expire'))
      let expired = ((expireDate.getTime()- Date.now())<0)
      if(expired){
        if(this.props.state.isAuthenticated){
          this.props.dispatch(refreshToken())
        }

      }
       return(
    <Route
      {...rest}
      render = { props => (
        <Layout>
          {this.props.state.isAuthenticated? <Component {...props} /> :  <Redirect to="/login" />}
        </Layout>
        )}/>
      )
  }
}

  const mapStateToProps = (state) => {
    return({
      state:state.authentication
    })
  }
  export default connect(mapStateToProps)(ProtectedRoute)
