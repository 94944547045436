import React from "react";
import { enableSearch, disableSearch } from "../../store/actions/searchActions";
import bn from "utils/bemnames";
import { withRouter, Redirect } from "react-router-dom";
import request from "../../utils/axios";
import { withI18n } from "react-i18next";

import {
  Navbar,
  // NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  ListGroup,
  ListGroupItem,
  Button,
  ButtonGroup,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { connect } from "react-redux"; //redux
import { logoutUser } from "../../store/actions/authActions";

import {
  MdNotificationsActive,
  MdNotificationsNone,
  MdClearAll,
  MdExitToApp
} from "react-icons/lib/md";
import { FaPencil } from "react-icons/lib/fa";

import Avatar from "components/Avatar";
import { UserCard } from "components/Card";
import Notifications from "components/Notifications";
import SearchInput from "components/SearchInput";

import withBadge from "hocs/withBadge";

import { notificationsData } from "demos/header";

const bem = bn.create("header");

const MdNotificationsActiveWithBadge = withBadge({
  size: "md",
  color: "primary",
  style: {
    top: -10,
    right: -10,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center"
  },
  children: <small>5</small>
})(MdNotificationsActive);

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    prev: ""
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover
    });
  };
  handleLogout = e => {
    e.preventDefault();
    this.props.logout();
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();
    document.querySelector(".cr-sidebar").classList.toggle("cr-sidebar--open");
  };

  getProfile = () => {
    return JSON.parse(localStorage.getItem("profile"));
  };

  getEmail = () => {
    let profile = this.getProfile();
    if (profile) {
      if (profile.email) {
        return profile.email;
      }
    }

    return "";
  };

  getLang = () => {
    return localStorage.getItem("lang");
  };

  componentWillMount() {
    this.t = this.props.t;
    let resourceName = this.props.location.pathname.split("/")[1];
    let resources = this.props.state.dashboard.resources;
    for (let i in resources) {
      if (resources[i].resource === resourceName) {
        this.setState({ resource: resources[i] });
        if (resources[i].search) {
          let res = resources[i];
          this.props.enableSearch(
            res.resource,
            res.search.fields,
            res.search.display
          );
        } else {
          this.props.disableSearch();
        }
        break;
      }
    }

    this.unlisten = this.props.history.listen((location, action) => {
      let resourceName = location.pathname.split("/")[1];
      let resources = this.props.state.dashboard.resources;
      for (let i in resources) {
        if (resources[i].resource === resourceName) {
          if (resources[i].search) {
            let res = resources[i];
            this.props.enableSearch(
              res.resource,
              res.search.fields,
              res.search.display
            );
          } else {
            this.props.disableSearch();
          }
          break;
        }
      }
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  isView = () => {
    let possibleView = this.props.location.pathname.split("/")[2];
    if (this.state.resource === undefined) {
      return false;
    }
    let views = this.state.resource.views;
    for (let i in views) {
      if (views[i].id === possibleView) {
        return true;
      }
    }
    return false;
  };
  handleAction = action => {
    let resource = this.state.resource;
    if (action.type === "delete_all") {
      let confirm = window.confirm("are you sure?");
      if (confirm) {
        request({}, `/${resource.resource}/delete_all`, "destroy").then(
          response => {}
        );
      }
    } else {
      let to = `${
        resource.source ? resource.source.url : "/" + resource.resource
      }/${action.id}`;
      this.setState({ redirect: true, to });
    }
  };
  openFilter = () => {
    document.querySelector(".filter").classList.toggle("col-2");
    document.querySelector(".filter").classList.toggle("filter--open");
    document.querySelector(".children").classList.toggle("col-10");
    document.querySelector(".children").classList.toggle("col-12");
  };
  changeLanguage = event => {
    localStorage.setItem("lang", event.target.value);
    window.location.reload();
  };
  render() {
    const { t } = this.props;
    var lang = this.getLang();

    var languages = Object.keys(this.props.state.dashboard.available_languages);
    languages = languages.filter(language => language !== lang);

    const { isNotificationConfirmed } = this.state;
    return (
      <React.Fragment>
        <Navbar light expand className={bem.b("bg-white")}>
          <Nav navbar className="mr-2">
            <Button outline onClick={this.handleSidebarControlButton}>
              <MdClearAll size={25} />
            </Button>
          </Nav>
          <Nav navbar className="mx-auto">
            {this.props.state.search.render ? (
              <SearchInput
                className="searchInput"
                resource={this.props.state.search.resource}
                params={this.props.state.search.params}
                form={this.props.state.search.form}
              />
            ) : null}
          </Nav>
          <Nav navbar className={bem.e("nav-right")}>
            <NavItem className="d-inline-flex">
              <NavLink id="Popover1" className="position-relative">
                {isNotificationConfirmed ? (
                  <MdNotificationsNone
                    size={25}
                    className="text-secondary can-click"
                    onClick={this.toggleNotificationPopover}
                  />
                ) : (
                  <MdNotificationsActiveWithBadge
                    size={25}
                    className="text-secondary can-click animated swing infinite"
                    onClick={this.toggleNotificationPopover}
                  />
                )}
              </NavLink>
              <Popover
                placement="bottom"
                isOpen={this.state.isOpenNotificationPopover}
                toggle={this.toggleNotificationPopover}
                target="Popover1"
              >
                <PopoverBody>
                  <Notifications notificationsData={notificationsData} />
                </PopoverBody>
              </Popover>
            </NavItem>

            <NavItem>
              <NavLink id="Popover2">
                <Avatar
                  onClick={this.toggleUserCardPopover}
                  className="can-click"
                />
              </NavLink>
              <Popover
                placement="bottom-end"
                isOpen={this.state.isOpenUserCardPopover}
                toggle={this.toggleUserCardPopover}
                target="Popover2"
                className="p-0 border-0"
                style={{ minWidth: 250 }}
              >
                <PopoverBody className="p-0 border-light">
                  <UserCard title={this.getEmail()} className="border-light">
                    <ListGroup flush>
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light"
                      >
                        <FormGroup size="xs">
                          <Label for="exampleSelect">{t("Language")}</Label>
                          <Input
                            defaultValue={lang}
                            type="select"
                            onChange={event => {
                              this.changeLanguage(event);
                            }}
                            name="select"
                            id="exampleSelect"
                          >
                            <option>...</option>
                            <option key={lang} value={lang}>
                              {
                                this.props.state.dashboard.available_languages[
                                  lang
                                ]
                              }
                            </option>
                            {languages.map(key => {
                              return (
                                <option key={key} value={key}>
                                  {
                                    this.props.state.dashboard
                                      .available_languages[key]
                                  }
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </ListGroupItem>
                      <ListGroupItem
                        onClick={() => {
                          this.setState({ reset: true });
                        }}
                        tag="button"
                        action
                        className="border-light"
                      >
                        <FaPencil /> {t("Change Password")}
                      </ListGroupItem>
                      <ListGroupItem
                        onClick={this.handleLogout}
                        tag="button"
                        action
                        className="border-light"
                      >
                        <MdExitToApp /> {t("Signout")}
                      </ListGroupItem>
                    </ListGroup>
                  </UserCard>
                </PopoverBody>
              </Popover>
            </NavItem>
          </Nav>
        </Navbar>
        {this.isView() ? (
          <Navbar light expand className={bem.b("bg-white")}>
            <Nav navbar className={bem.e("nav-right")}>
              <NavItem className="d-inline-flex">
                <div>
                  <strong>{t(this.state.resource.display_name)}</strong>
                </div>
              </NavItem>
            </Nav>
            {this.state.resource ? (
              <ButtonGroup size="sm" className="buttonGroup">
                {this.state.resource.resource_actions.map((action, index) => (
                  <Button
                    key={index}
                    color="deoco"
                    className="text-uppercase"
                    onClick={() => {
                      this.handleAction(action);
                    }}
                  >
                    <i className={action.icon}></i>
                    {"  " + t(action.label)}
                  </Button>
                ))}
              </ButtonGroup>
            ) : null}
            {this.state.resource.filters ? (
              <Button
                size="sm"
                color="deoco"
                className="text-uppercase"
                onClick={() => {
                  this.openFilter();
                }}
              >
                <i className="fa fa-filter" aria-hidden="true"></i>
                <span> {t("Filters")}</span>
              </Button>
            ) : null}
          </Navbar>
        ) : null}
        {this.state.redirect ? <Redirect to={this.state.to} /> : null}
        {this.state.reset ? <Redirect to={"/resetPassword"} /> : null}
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(logoutUser());
    },
    enableSearch: (resource, params, form) => {
      dispatch(enableSearch({ params, resource, form }));
    },
    disableSearch: () => {
      dispatch(disableSearch());
    }
  };
};

const mapStatetoProps = state => {
  return {
    state: state
  };
};

export default withI18n()(
  withRouter(
    connect(
      mapStatetoProps,
      mapDispatchToProps
    )(Header)
  )
);
