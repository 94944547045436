import React, { Component } from "react";
import request from "../utils/axios";

class TitanImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.initialize();
  }

  initialize() {
    let res = this.props.resource.resource;
    let id = this.props.item.id;
    let field = this.props.field;
    let hasImage = Boolean(this.props.item[field]);
    if (hasImage) {
      request({}, `/${res}/${id}/download/${field}`, "index").then(response => {
        this.setState({ url: response.url });
      });
    } else {
      this.setState({ url: "https://via.placeholder.com/100x100" });
    }
  }

  render() {
    return (
      <img alt="Titan " height="100px" width="100px" src={this.state.url}></img>
    );
  }
}

export default TitanImage;
