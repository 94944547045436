import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Axios from "axios";
import { configureStore } from "./store/store";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import getI18n from "./i18n";
import xhrHookForS3 from "./xhr_hook_for_s3";

import * as Sentry from "@sentry/browser";
import Favicon from "react-favicon";
import TitanFileLinkComponent from "./components/TitanFileLinkComponent";
import MapComponent from "./components/MapComponent";
import ColorComponent from "./components/ColorComponent";
import CustomTextAreaComponent from "./components/CustomTextAreaComponent";

var jsonSpec = "";

registerCustomComponent();
if (process.env.NODE_ENV === "development") {
  console.log("Development mode...");
  /*** CHANGE HERE FOR DEVELOPMENT ***/

  jsonSpec = "/data/dashboard.json";

  /*** END ***/
} else if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://4d4b6d075a774904a2459b0f4d5f98f7@sentry.io/1305210"
  });
  console.log("Sentry init.");
  jsonSpec = `https://s3.amazonaws.com/titan-shapeshift-spec/${window.location.hostname}.json`;
}

console.log(`Loading... ${jsonSpec}`);
Axios.get(jsonSpec).then(dashboard => {
  localStorage.setItem("base_url", dashboard.data.base_url);
  xhrHookForS3();
  let i18n = getI18n(dashboard.data.i18n);
  document.title = i18n.t(dashboard.data.title);
  ReactDOM.render(
    <Provider store={configureStore(dashboard.data)}>
      <I18nextProvider i18n={i18n}>
        <Favicon url={dashboard.data.logo_url} />
        <App />
      </I18nextProvider>
    </Provider>,
    document.getElementById("root")
  );
});

function registerCustomComponent() {
  if (
    typeof global === "object" &&
    global.Formio &&
    global.Formio.registerComponent
  ) {
    global.Formio.registerComponent("titan_file_link", TitanFileLinkComponent);
    global.Formio.registerComponent("map", MapComponent);
    global.Formio.registerComponent("color", ColorComponent);
    global.Formio.registerComponent("textarea", CustomTextAreaComponent);
  }
}
