import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map'
let getDate = (item, grouping) => {
    let start = moment.unix(item[grouping].date.start).format('lll');
    let end = moment.unix(item[grouping].date.end).format('lll');
    return {
        type: 'date',
        obj: item[grouping],
        render: `${start}--${end}`
    }
}
let getCurrency = (item, grouping) => {
    return {
        type: 'currency',
        obj: item[grouping],
        render: getSymbolFromCurrency(item[grouping].currency.symbol) + item[grouping].currency.amount
    }
}


export default (item, grouping) => {
    if (item[grouping]) {
        let returnObj = {
            render: item[grouping],
            type: "text",
            obj: item[grouping]
        }
        if (item[grouping].date) {
            returnObj = getDate(item, grouping)
        }
        if (item[grouping].currency) {
            returnObj = getCurrency(item, grouping)
        }
        return returnObj;

    }
}
