import { combineReducers } from 'redux'
import authReducer from './reducers/authReducer';
import bootstrapReducer from './reducers/bootstrapReducer'
import searchReducer from './reducers/searchReducer'
import notificationReducer from './reducers/notificationReducer'
import filterReducer from './reducers/filterReducer'

let rootReducerWrapper=(initialState)=>{

let rootReducer = combineReducers(
    {   
        dashboard:bootstrapReducer(initialState),
        authentication:authReducer,
        search:searchReducer,
        notification:notificationReducer,
        filter:filterReducer
        
    })
    return rootReducer
}
    export default rootReducerWrapper;