import React, { Component } from "react";
import { Form } from "react-formio";
import { Redirect } from "react-router";
import "formiojs/dist/formio.full.css";
import { Col } from "reactstrap";
import request from "../utils/axios";
import formTypes from "../utils/formTypes";
import { getComponent } from "formiojs/utils/formUtils.js";
import { submitBehaivour, message, getFinalObject } from "../utils/formTypes";
import { connect } from "react-redux"; //redux
import {
  fireNotification,
  disableNotification,
} from "../store/actions/notificationActions";
import "formiojs/dist/formio.contrib.js";
import normalizeFormFields from "../utils/normalizeFormFields";
import Comments from "./Comments";
import { withI18n } from "react-i18next";
let getBehaviour = (form) => {
  if (getComponent(form, "submit")) {
    return getComponent(form, "submit").after_success;
  }
};
class DynamicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: null,
      rerender: false,
      loading: false,
    };
    this.toSubmit = true;
    this.i18n = this.props.i18n;
    this.formInstance = React.createRef();
  }

  onCustomEvent(props, e) {
    let url = props.url;

    if (e.data) {
      let action = props.actionType ? props.actionType : "create";
      let obj = Object.assign({}, e.data);
      let ignoreRoleAssignation = e.component.ignore_role_assignation;
      let form = props.form.components;
      let resource = props.resource;
      let rerender = this.state.rerender;
      delete obj.submit;
      let opts = {};

      // common part for update and create
      obj = getFinalObject(props.form.components, obj);
      obj = formTypes(obj, props.form.components);

      if (action === "update") {
        let storedItem = Object.assign({}, props.serverItem);
        let originalSubmission = Object.assign({}, e.data);

        obj = normalizeFormFields(
          storedItem,
          originalSubmission,
          obj,
          props.form.components
        );
      }

      if (action === "invite") {
        url = "/invite_users";
      }
      if (
        this.formInstance.formio.checkValidity(
          this.formInstance.formio.submission.data,
          true
        )
      ) {
        this.setState({ loading: true });

        if (ignoreRoleAssignation) {
          opts.ignoreRoleAssignation = ignoreRoleAssignation;
        }

        request(obj, url, action, opts).then((resp) => {
          this.setState({ loading: false });
          if (!props.closeModal) {
            this.setState(
              submitBehaivour(resource, resp.id, getBehaviour(form), rerender)
            );
          } else {
            props.closeModal.closeModal();
            props.closeModal.reload();
          }
          props.fireNotification({
            title: "",
            message: message(props.form.components),
            level: "success",
          });
        });
      } else {
        props.fireNotification({
          title: "",
          message: "Please fix validation error",
          level: "success",
        });
      }
    }
  }

  style = this.props.minView
    ? {}
    : { height: "calc(100vh - 77px)", overflow: "auto" };
  toSubmit;

  render() {
    if (this.state.loading === true) {
      return (
        <i
          className="fa fa-circle-o-notch fa-spin"
          style={{ fontSize: "54px", marginLeft: "50%", marginBottom: "50px" }}
        />
      );
    } else {
      return (
        <div style={this.style} className="row justify-content-center">
          {this.state.to ? <Redirect to={this.state.to} /> : null}
          <Col
            md={this.props.fullWidth ? 12 : 8}
            style={{ marginBottom: this.props.minView ? 0 : 60 }}
          >
            <Form
              style={{ paddingBottom: "60px" }}
              ref={(form) => (this.formInstance = form)}
              form={this.props.form}
              submission={{ data: this.props.submission }}
              onCustomEvent={(e) => this.onCustomEvent(this.props, e)}
              options={{
                i18n: this.props.i18n,
                language: localStorage.getItem("lang"),
              }}
            />
            {this.props.comments ? <Comments url={this.props.url} /> : null}
          </Col>
        </div>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fireNotification: (payload) => {
      dispatch(fireNotification(payload));
    },
    disableNotification: () => {
      dispatch(disableNotification());
    },
  };
};

const mapStatetoProps = (state) => {
  return {
    state: state,
  };
};

export default withI18n()(
  connect(mapStatetoProps, mapDispatchToProps)(DynamicForm)
);
