import React from "react";
import request from "../utils/axios";
import HTML from "formiojs/components/html/HTML";
import ReactDOMServer from "react-dom/server";

export default class TitanFileLinkComponent extends HTML {
  static schema(...extend) {
    return HTML.schema(
      {
        type: "titan_file_link",
        label: "link",
        field: "",
        resource: "",
        content: "",
        fetched: false
      },
      ...extend
    );
  }
  get defaultSchema() {
    return TitanFileLinkComponent.schema();
  }

  static get builderInfo() {
    return {
      title: "TitanFileLinkComponent",
      group: "file",
      icon: "code",
      weight: 0,
      documentation: "http://help.form.io/userguide/#component",
      schema: TitanFileLinkComponent.schema()
    };
  }

  // powered by formiojs Component .redraw() :)
  async redraw() {
    // Don't bother if we have not built yet.
    if (!this.element || !this.element.parentNode) {
      // Return a non-resolving promise.
      return Promise.resolve();
    }

    let item_id = this.interpolate(this.component.content);
    let resource = this.component.resource;
    let field = this.component.field;

    if (!item_id) {
      return;
    }
    let response = await request(
      {},
      `/${resource}/${item_id}/download/${field}`,
      "index"
    );

    this.clear();
    let url = response["url"] || "#";

    let link = ReactDOMServer.renderToString(
      <a
        alt="titan_file_link"
        target="_blank"
        rel="noopener noreferrer"
        href={url}
      >
        Download
      </a>
    );

    // Since we are going to replace the element, we need to know it's position so we can find it in the parent's children.
    const parent = this.element.parentNode;
    const index = Array.prototype.indexOf.call(parent.children, this.element);
    this.element.outerHTML = link;
    this.element = parent.children[index];
    return this.attach(this.element);
  }
}
