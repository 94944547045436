
export const ADD_FILTERS = "ADD_FILTERS";
export const REMOVE_FILTERS = "REMOVE_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";

export  let  addFilter= (payload)=> {
    return {
      type: ADD_FILTERS,
      payload
    }
  }
  export  let  removeFilter= ()=> {
    return {
      type: REMOVE_FILTERS 
    }
  }
  export let resetFilter=()=>{
    return {
      type: RESET_FILTERS 
    }
  }

