import ResetPasswordMailerForm from "components/ResetPasswordMailerForm";
import React from "react";
import { Card, Col, Row } from "reactstrap";

class ResetPasswordMailerPage extends React.Component {
  render() {
    return (
      <Row
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll"
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <ResetPasswordMailerForm />
          </Card>
        </Col>
      </Row>
    );
  }
}

export default ResetPasswordMailerPage;
