import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import DynamicForm from "../DynamicForm";
import ItemDropdown from "./ItemDropdown";
import { withI18n } from "react-i18next";

class ItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <Modal toggle={this.props.closeModal} isOpen={true} size="lg">
        <ModalHeader toggle={this.props.closeModal}>
          {" "}
          {t(`${this.props.url} Details`)}
        </ModalHeader>
        {this.props.actions ? (
          <div className="col-md-2 offset-md-10  ">
            <ItemDropdown
              compact={false}
              url={this.props.url}
              itemId={this.props.item.id}
              item={this.props.item}
              editModal={this.props.editModal}
              refetch={this.props.refetch}
              actions={this.props.actions}
            />
          </div>
        ) : null}
        <ModalBody>
          <DynamicForm
            minView={true}
            url={this.props.commentsUrl}
            comments={this.props.comments}
            closeModal={this.props.closeModal}
            submission={this.props.item}
            form={this.props.form}
            fullWidth={true}
          />
        </ModalBody>
      </Modal>
    );
  }
}
ItemModal.propTypes = {
  item: PropTypes.object
};

export default withI18n()(ItemModal);
