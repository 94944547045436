import React from "react";
import request from "../utils/axios";
import { withI18n } from "react-i18next";
import { Button, Modal, ModalBody } from "reactstrap";
import EditModal from "./ItemActions/EditModal";
import { connect } from "react-redux"; //redux
import {
  fireNotification,
  disableNotification
} from "../store/actions/notificationActions";

class MapActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      modal: true,
      editModal: false
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps, modal: true });
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
    this.props.closeAction();
  };
  deleteItem = () => {
    let url = this.props.res;
    let id = this.props.item.id;
    request({}, `/${url}/${id}`, "destroy").then(response => {
      if (response) {
        this.props.fireNotification({
          title: "",
          message: "Delete succesful.",
          level: "success"
        });
      }
      this.props.reload();
      this.props.closeAction();
    });
  };

  returnDelete = (action, item) => {
    return (
      <div>
        <h2>Are you sure?</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "30px 20px 0px 20px"
          }}
        >
          <Button
            onClick={() => {
              this.deleteItem();
            }}
            color="deoco"
            style={{ margin: "0px 20px" }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              this.props.closeAction();
            }}
            color="deoco"
          >
            No
          </Button>
        </div>
      </div>
    );
  };
  returnItemAction = (action, item) => {
    this.setState({ editModal: true });
  };
  renderActions = () => {
    let action = this.props.action;
    let item = this.props.item;
    if (action.id === "destroy") {
      return this.returnDelete(action, item);
    } else {
      return this.returnItemAction(action, item);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {!this.state.editModal ? (
          <Modal
            modalTransition={{ timeout: 300 }}
            backdropTransition={{ timeout: 700 }}
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
          >
            <div>
              <button
                type="button"
                onClick={this.toggle}
                className="close pull-right"
                style={{ padding: "15px" }}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <React.Fragment>
                <div
                  className="pull-left"
                  style={{ margin: "20px", fontSize: "18px" }}
                >
                  {t(`${this.props.action.label} item`)}
                </div>
              </React.Fragment>
            </div>
            <ModalBody>{this.renderActions()}</ModalBody>
          </Modal>
        ) : (
          <EditModal
            id={this.props.item.id}
            url={this.props.res}
            action={this.props.action}
            resource={this.props.resource}
            closeModal={this.props.closeAction}
            reload={this.props.reload}
            form={this.props.action.form}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fireNotification: payload => {
      dispatch(fireNotification(payload));
    },
    disableNotification: () => {
      dispatch(disableNotification());
    }
  };
};

const mapStatetoProps = state => {
  return {
    state: state
  };
};

export default withI18n()(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )(MapActions)
);
