export default (view) => {
    switch(view.id){
        case 'map':
          return 'fas fa-map-marked-alt';
        case 'kanban':
            return  'fa fa-columns';
        case 'full_table':
            return 'fa fa-table';
        case 'calendar':
            return 'fa fa-calendar';
        case 'cards':
            return 'fa-id-card-o'
        default:
         return 'fa fa-dot-circle-o';
     
    }
    
}