/* global Quill */
 
import TextAreaComponent from "formiojs/components/textarea/TextArea";


export default class CustomTextAreaComponent extends TextAreaComponent {
  imageHandler(quillInstance) {
      var range = quillInstance.quill.getSelection();
      var value = prompt('What is the image URL');
      if(value){
          quillInstance.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
      }
  }
}