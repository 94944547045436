import React from "react";
import moment from "moment";
import bigCalendar from "react-big-calendar";
import request from "../utils/axios";
import fieldTypes from "../utils/fieldTypes";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import Toolbar from "../components/CustomToolbar";
import { connect } from "react-redux"; //redux
import params from "../utils/requestParams";
import {
  addFilter,
  removeFilter,
  resetFilter,
} from "../store/actions/filteractions";
import { withI18n } from "react-i18next";
import ItemModal from "../components/ItemActions/ItemModal";

import EditModal from "../components/ItemActions/EditModal";
require("moment/min/locales.min");

const BigCalendar = withDragAndDrop(bigCalendar);

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      events: [],
      page: 0,
    };
  }
  fixUrl() {
    let resource = this.props.resource;
    return resource.source ? resource.source.url : resource.resource;
  }
  closeEditModal = () => {
    this.setState({ editModal: false });
  };
  editModal = (id, action) => {
    this.setState({
      editModal: true,
      editModalAction: action,
      editModalFileds: { id, form: action.form },
    });
  };

  generateEvents = (items, date, append) => {
    let events = [];
    events = items.map((item, index) => {
      var key = Object.keys(this.props.view.title_field)[0];
      let dateField = this.props.view.date_field;
      var end;
      var start = item[dateField].date.start * 1000;

      if (item[dateField].date.end) {
        end = new Date(item[dateField].date.end * 1000);
      } else {
        end = start;
      }

      return {
        start: new Date(start),
        end: new Date(end),
        allDay: false,
        id: item.id,
        color: item.color,
        item,
        title: fieldTypes({
          resource: this.props.resource,
          item,
          key,
          field: this.props.view.title_field[key],
        }),
        resource: {
          id: index,
          resourceId: index,
        },
      };
    });

    if (append) {
      events = this.state.events.concat(events);
    }

    this.setState({ events, date });
  };
  //////////////////////////RESIZE//////////////////////////////////////////
  resizeEvent = ({ event, start, end }) => {
    const { events } = this.state;
    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    this.setState({
      events: nextEvents,
    });
    let newDate = {
      date: {
        end: moment(end).valueOf() / 1000,
        start: moment(start).valueOf() / 1000,
        timezone: "UTC",
      },
    };
    let payload = {};
    payload[this.props.view.date_field] = newDate;
    let params = this.state.params ? this.state.params : "";
    request(payload, `/${this.fixUrl()}/${event.id}${params}`, "update").then(
      (response) => {
        if (!response) {
          alert("request not sucessful");
        }
      }
    );
  }; //////////////////////////DND//////////////////////////////////////////

  onEventDrop = (data) => {
    let events = this.state.events;
    let newEvents = events.filter((event) => {
      if (event.id === data.event.id) {
        event.start = data.start;
        event.end = data.end;
        return event;
      }
      return event;
    });

    this.setState({
      events: newEvents,
    });

    let newDate = {
      date: {
        end: moment(data.end).valueOf() / 1000,
        start: moment(data.start).valueOf() / 1000,
        timezone: "UTC",
      },
    };
    let payload = {};
    payload[this.props.view.date_field] = newDate;

    request(payload, `/${this.fixUrl()}/${data.event.id}`, "update").then(
      (response) => {
        if (!response) {
          alert("request not sucessful");
        }
      }
    );
  };

  componentDidMount() {
    let view = this.props.view.default_window[0];
    let date = new Date();
    let range = this.getInterval(date, view);
    this.makeRequest(range, date, false, 0, false);
  }
  //////////////////////////HELPERS//////////////////////////////////////////
  makeRequest = (range, date, append, page, fetchAll) => {
    this.closeModal();
    if (this.state.view === "agenda") {
      this.setState({ range }, () => {
        let params = this.state.params ? this.state.params : "";
        request(
          {},
          `/${this.fixUrl()}?q[${this.props.view.date_field}_stdate_gteq]=${
            range.start
          }&q[${this.props.view.date_field}_stdate_lteq]=${
            range.end
          }&page=${page}${params}`,
          "index"
        ).then((response) => {
          this.generateEvents(
            response.data[this.props.resource.resource],
            date,
            append
          );
        });
      });
    }
    this.setState({ range }, () => {
      let params = this.state.params ? this.state.params : "";
      request(
        {},
        `/${this.fixUrl()}?q[${this.props.view.date_field}_stdate_gteq]=${
          range.start
        }&q[${this.props.view.date_field}_stdate_lteq]=${
          range.end
        }&page=${page}${params}&per_page=100&q[s]=updated_at+asc`,
        "index"
      ).then((response) => {
        this.generateEvents(
          response.data[this.props.resource.resource],
          date,
          append
        );
      });
    });
  };
  getInterval = (date, view) => {
    let start, end;
    if (view === "day") {
      start = moment(date).startOf("day");
      end = moment(date).endOf("day");
    } else if (view === "week") {
      start = moment(date).startOf("week");
      end = moment(date).endOf("week");
    } else if (view === "month") {
      start = moment(date).startOf("month");
      end = moment(date).endOf("month");
    } else if (view === "agenda") {
      start = moment(date).startOf("day");
      end = moment(date).endOf("day").add(1, "month");
    }
    return {
      start: start.unix(),
      end: end.unix(),
    };
  };
  loadMore = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRequest(
          this.state.range ? this.state.range : null,
          this.state.date,
          true,
          this.state.page
        );
      }
    );
  };
  selectEvent = (event, e) => {
    let actions = this.props.view.view_actions.map((action) => {
      let actionThatMatch = this.props.resource.item_actions.filter(
        (resAction) => resAction.id === action
      );
      return actionThatMatch[0];
    });
    actions = actions.filter((a) => a !== undefined);
    let viewAction = actions.filter((action) => action.id === "view");
    let otherActions = actions.filter((action) => action.id !== "view");
    if (viewAction.length === 1) {
      this.setState({
        itemModal: event.item,
        viewAction: viewAction[0],
        otherActions,
      });
    }
  };
  closeModal = () => {
    this.setState({ itemModal: undefined });
  };

  render() {
    return (
      <div>
        {this.state.editModal ? (
          <EditModal
            action={this.state.editModalAction}
            id={this.state.editModalFileds.id}
            url={this.fixUrl()}
            resource={this.props.resource}
            closeModal={this.closeEditModal}
            form={this.state.editModalFileds.form}
            reload={() => {
              this.makeRequest(
                this.state.range ? this.state.range : null,
                this.state.date,
                false,
                this.state.page
              );
            }}
          />
        ) : null}
        {this.state.itemModal ? (
          <ItemModal
            item={this.state.itemModal}
            closeModal={this.closeModal}
            form={this.state.viewAction.form}
            actions={this.state.otherActions}
            editModal={this.editModal}
            url={this.props.resource.resource}
            comments={this.state.viewAction.comments}
            commentsUrl={`${this.props.resource.resource}/${this.state.itemModal.id}`}
            reload={() => {
              this.makeRequest(
                this.state.range ? this.state.range : null,
                this.state.date,
                false,
                this.state.page
              );
            }}
          />
        ) : null}
        <BigCalendar
          onSelectEvent={(event, e) => {
            this.selectEvent(event, e);
          }}
          culture={localStorage.getItem("lang")}
          components={{
            toolbar: Toolbar(() => {
              this.loadMore();
            }),
            agenda: {
              event: (e) => {
                return (
                  <div
                    onClick={() => {
                      this.selectEvent(e.event);
                    }}
                  >
                    {e.title}
                  </div>
                );
              },
              time: (e) => {
                return <div>{e.label}</div>;
              },
              date: (e) => {
                return <div>{e.label}</div>;
              },
            },
          }}
          defaultDate={new Date()}
          selectable
          onView={(view) => {
            this.setState({ page: 0, view }, () => {
              let range = this.getInterval(this.state.date, view);
              this.makeRequest(range, this.state.date, false, 0);
            });
          }}
          onNavigate={(date, view) => {
            if (view === "agenda") {
              this.setState({ page: 0 }, () => {
                let range = this.getInterval(date, view);
                this.makeRequest(range, date, false, 0, true);
              });
            }
            this.setState({ page: 0 }, () => {
              let range = this.getInterval(date, view);
              this.makeRequest(range, date, false, 0, false);
            });
          }}
          localizer={bigCalendar.momentLocalizer(moment)}
          resizable
          onEventDrop={this.onEventDrop}
          onEventResize={this.resizeEvent}
          onSelectSlot={this.newEvent}
          defaultView={this.props.view.default_window[0]}
          events={this.state.events}
          style={{ height: "calc(100vh - 125px)" }}
          eventPropGetter={(event) => {
            let item = event.item;
            let colorKey = this.props.view.colorEventWith;
            let color = undefined; //use big calendar default
            if (colorKey) {
              color = item[colorKey];
            }

            return {
              style: {
                backgroundColor: color,
              },
            };
          }}
        />
        {this.props.state.filter.filters ? this.enableFilters() : null}
        {this.props.state.filter.reset ? this.resetFilters() : null}
      </div>
    );
  }
  resetFilters = () => {
    this.props.filterOff();
    this.setState({ params: undefined }, () => {
      this.makeRequest(
        this.state.range ? this.state.range : null,
        this.state.date,
        false,
        this.state.page
      );
    });
    return null;
  };
  enableFilters = () => {
    this.props.filterOff();
    let p = params(
      this.props.state.filter.filters,
      this.props.resource.filters.form
    );
    this.setState({ params: p }, () => {
      this.makeRequest(
        this.state.range ? this.state.range : null,
        this.state.date,
        false,
        this.state.page
      );
    });
    return null;
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    filterOn: (payload) => {
      dispatch(addFilter(payload));
    },
    filterOff: () => {
      dispatch(removeFilter());
    },
    filterReset: () => {
      dispatch(resetFilter());
    },
  };
};
const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(Calendar)
);
