import React from "react";
import request from "../utils/axios";
import { connect } from "react-redux"; //redux
import { Col } from "reactstrap";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { resetPasswordMailer } from "../store/actions/authActions";

class ResetPasswordMailerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestSent: false
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    let data = {
      email: this.username.value
    };
    request(data, "/password/reset", "create").then(response => {
      this.setState({ requestSent: true });
    });
  };

  render() {
    return (
      <div className="row justify-content-center" ref={this.elementRef}>
        {this.state.requestSent ? (
          <Col md={this.props.fullWidth ? 12 : 8}>
            <Form>
              <FormGroup>
                <Label for="email">
                  Ti è stata inviata una mail con il link per il cambio password
                </Label>
              </FormGroup>
            </Form>
          </Col>
        ) : (
          <Col md={this.props.fullWidth ? 12 : 8}>
            <Form>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input innerRef={node => (this.username = node)} />
                <hr />
                <Button
                  size="lg"
                  className="bg-gradient-theme-left border-0"
                  block
                  onClick={this.handleSubmit}
                >
                  Reset Password
                </Button>
              </FormGroup>
            </Form>
          </Col>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetPasswordMailer: username => {
      dispatch(resetPasswordMailer(username));
    }
  };
};

const mapStatetoProps = state => {
  return {
    state: state
  };
};

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(ResetPasswordMailerForm);
