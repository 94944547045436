import { getComponent, flattenComponents } from "formiojs/utils/formUtils.js";
import moment from "moment";
import _ from "lodash";
function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}
export let message = form => {
  if (getComponent(form, "")) {
    let type = getComponent(form, "submit").after_success.message;
    return type;
  }
};
export let getFinalObject = (form, object) => {
  let keys = Object.keys(flattenComponents(form, false));
  keys = keys.filter(key => key !== "" || key !== "submit");
  for (var property in object) {
    if (object.hasOwnProperty(property)) {
      if (!keys.includes(property)) {
        delete object[property];
      }
    }
  }
  return object;
};
export let getType = (key, form) => {
  if (getComponent(form, key)) {
    let type = getComponent(form, key).titan_field.type;
    return type;
  }
};

export let getSelectOptions = (key, form) => {
  if (getComponent(form, key)) {
    let values = getComponent(form, key).data.values;
    return values;
  }
};
export let getNestedType = (key, form, nestedKey) => {
  if (getComponent(form, `${nestedKey}.${key}`)) {
    let type = getComponent(form, `${nestedKey}.${key}`).titan_field;
    if (type !== "undefined") {
      return type.type;
    }
    return "undefied";
  }
};
export let submitBehaivour = (resource, id, Behaviour, rerender) => {
  let resourceName = resource.source ? resource.source.link : resource.resource;
  switch (Behaviour.behavior) {
    case "redirect_to_default_view":
      return {
        to: `/${resourceName}/${
          resource.default_view ? resource.default_view : resource.views[0].id
        }`,
        message: Behaviour.behavior
      };
    case "redirect_to_item":
      return {
        to: `/${resourceName}/${id}/view`,
        message: Behaviour.behavior
      };
    case "redirect_to_action":
      return {
        to: `/${resourceName}/${id}/${Behaviour.action_id}`,
        message: Behaviour.behavior
      };
    case "reset":
      return {
        rerender: !rerender,
        message: Behaviour.behavior
      };
    default:
      return {};
  }
};

let mapRelationshipOnCreate = (key, obj) => {
  console.log("On create replace relationship");
  let newObj = Object.assign({}, obj);
  if (Array.isArray(obj[key])) {
    var compact = obj[key].filter(e => !_.isNil(e) && !_.isEmpty(e));
    newObj[key] = compact.map(e => e.id);
  } else {
    console.log(key);
    let value = obj[key];
    if (_.isNil(value) || _.isEmpty(value) || value === "") {
      newObj[key] = [];
    } else {
      newObj[key] = [value.id];
    }
  }
  console.log(newObj);
  return newObj;
};

let getCategory = (key, obj) => {
  let newObj = Object.assign({}, obj);
  if (Array.isArray(obj[key])) {
  } else {
    newObj[key] = [obj[key]];
  }
  return newObj;
};

let extractNameFromDateField = label => {
  let newKey = label.substring(0, label.lastIndexOf("_"));
  return newKey;
};
let getStart = (key, obj) => {
  let newObj = Object.assign({}, obj);
  let newKey = extractNameFromDateField(key);
  newObj[newKey] = newObj.hasOwnProperty(newKey)
    ? newObj[newKey]
    : { date: {} };
  newObj[newKey].date.start = moment(obj[key]).valueOf() / 1000;
  newObj[newKey].date["timezone"] = "UTC";
  delete newObj[key];
  return newObj;
};
let getEnd = (key, obj) => {
  let newObj = obj;
  let newKey = extractNameFromDateField(key);
  newObj[newKey] = newObj.hasOwnProperty(newKey)
    ? newObj[newKey]
    : { date: {} };
  newObj[newKey].date.end = moment(obj[key]).valueOf() / 1000;
  newObj[newKey].date["timezone"] = "UTC";
  delete newObj[key];
  return newObj;
};
let getCurrency = (key, obj) => {
  let newObj = obj;
  newObj[key] = {
    currency: {
      amount: obj[key],
      symbol: "EUR"
    }
  };
  return newObj;
};

let getImage = (key, obj) => {
  if (obj[key].length > 0) {
    let newObj = Object.assign(obj);
    let oldObjAtKey = newObj[key][0];
    newObj[key] = {};
    newObj[key].file = {};
    newObj[key].file.key = oldObjAtKey["key"];
    newObj[key].file.type = oldObjAtKey["type"];
    newObj[key].file.size = oldObjAtKey["size"];
    newObj[key].file.storage = "s3";
    return newObj;
  } else {
    if (Array.isArray(obj[key])) {
      let newObj = Object.assign(obj);
      delete newObj[key];
      return newObj;
    } else {
      return obj;
    }
  }
};
let getNested = (nestedKey, obj, form) => {
  if (obj[nestedKey].length > 0 && !isEmpty(obj[nestedKey][0])) {
    obj[nestedKey] = obj[nestedKey].map(nestedObj => {
      let newObj = Object.assign(nestedObj);

      for (var key in nestedObj) {
        if (getNestedType(key, form, nestedKey) === "undefined") {
          delete newObj[key];
        }

        if (nestedObj.hasOwnProperty(key)) {
          if (getNestedType(key, form, nestedKey) === "relationship") {
            newObj = mapRelationshipOnCreate(key, nestedObj);
          }

          if (getNestedType(key, form, nestedKey) === "date") {
            if (key.includes("start")) {
              newObj = getStart(key, newObj);
            } else {
              newObj = getEnd(key, newObj);
            }
          }
          if (getNestedType(key, form, nestedKey) === "currency") {
            newObj = getCurrency(key, newObj);
          }
          if (getNestedType(key, form, nestedKey) === "nested") {
            newObj = getNested(key, newObj);
          }
          if (getNestedType(key, form, nestedKey) === "effimeral") {
            delete newObj[key];
          }
          if (getNestedType(key, form, nestedKey) === "image") {
            if (newObj[key].length > 0) {
              newObj = getImage(key, newObj);
            } else {
              let deleteField = Object.assign({}, newObj);
              delete deleteField[key];
              newObj = deleteField;
            }
          }
        }
      }
      return newObj;
    });

    return obj;
  } else {
    delete obj[nestedKey];
    return obj;
  }
};
export default (obj, form) => {
  let newObj = Object.assign({}, obj);
  for (var key in newObj) {
    if (newObj.hasOwnProperty(key)) {
      if (getType(key, form) === "relationship") {
        newObj = mapRelationshipOnCreate(key, newObj);
      }

      if (getType(key, form) === "category") {
        newObj = getCategory(key, newObj);
      }

      if (getType(key, form) === "date") {
        if (key.includes("start")) {
          newObj = getStart(key, newObj);
        } else {
          newObj = getEnd(key, newObj);
        }
      }
      if (getType(key, form) === "currency") {
        newObj = getCurrency(key, newObj);
      }
      if (getType(key, form) === "nested") {
        newObj = getNested(key, newObj, form);
      }
      if (getType(key, form) === "effimeral") {
        let deleteObj = Object.assign({}, newObj);
        delete deleteObj[key];
        newObj = deleteObj;
      }
      if (getType(key, form) === "image") {
        if (obj[key].length > 0) {
          newObj = getImage(key, newObj);
        } else {
          let deleteField = Object.assign({}, newObj);
          delete deleteField[key];
          newObj = deleteField;
        }
      }
    }
  }
  return newObj;
};
