import React, { Component } from "react";
import { Form } from "react-formio";
import "formiojs/dist/formio.full.css";
import { Col } from 'reactstrap';
import { connect } from 'react-redux';//redux
import { fireNotification, disableNotification } from '../store/actions/notificationActions'
import 'formiojs/dist/formio.contrib.js';

import { withI18n } from "react-i18next";
class DynamicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
    }
    this.i18n = this.props.i18n
    this.elementRef = React.createRef();
  }



  
  render() {
    return (
      <div style={this.style} className="row justify-content-center " ref={this.elementRef}>
      
        <Col md={12}>
          <Form
            src={this.props.form}
            submission={{ data: this.props.submission }}
            options={{ i18n: this.props.state.dashboard.i18n, language: "it" }}
          />
        </Col>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fireNotification: (payload) => {
      dispatch(fireNotification(payload))
    },
    disableNotification: () => {
      dispatch(disableNotification())
    }
  }
}

const mapStatetoProps = (state) => {
  return ({
    state: state
  })
}

export default withI18n()(connect(mapStatetoProps, mapDispatchToProps)(DynamicForm));
