
export default () => {
  var oldOpen = XMLHttpRequest.prototype.open
  XMLHttpRequest.prototype.open = function(){
    if(arguments[1] === "/storage/s3"){
      arguments[1] = localStorage.getItem("base_url")+"/storage/s3"
    }
    // call the native send()
    oldOpen.apply(this, arguments);
  }
}
