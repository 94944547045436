import { Content, Footer, Header, Sidebar,FilterBar } from 'components/Layout';

import React from 'react';
import {
  // MdCardGiftcard,
  MdLoyalty,
} from 'react-icons/lib/md';
import { withI18n } from "react-i18next";
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { connect } from 'react-redux';//redux
import { fireNotification, disableNotification } from '../../store/actions/notificationActions'

class MainLayout extends React.Component {
  static isSidebarOpen() {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  }
  static isFilterBarOpen(){
    return document
      .querySelector('.cr-filter')
      .classList.contains('cr-filter--open');
  }

  componentWillReceiveProps({ breakpoint }) {
    if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }
  }
  newFunc = () => {

  }
t=this.props.t
  componentDidMount() {
    this.checkBreakpoint(this.props.breakpoint);

    // setTimeout(() => {
    //   if (!this.notificationSystem) {
    //     return;
    //   }
    //   this.notificationSystem.addNotification({
    //     title: <MdImportantDevices />,
    //     message: 'Welome to Reduction Admin!',
    //     level: 'info',
    //   });
    // }, 500);

    // setTimeout(() => {
    //   if (!this.notificationSystem) {
    //     return;
    //   }

    //   this.notificationSystem.addNotification({
    //     title: <MdLoyalty />,
    //     message:
    //       'Reduction is carefully designed template powered by React and Bootstrap4!',
    //     level: 'success ',
    //   })
    // }, 1500);
  }
  newFunc = () => {
    setTimeout(() => {
      if (!this.notificationSystem) {
        return;
      }
      if(this.props.state.notification.message){
        this.notificationSystem.addNotification({
          title: <MdLoyalty />,
          message:this.t(this.props.state.notification.message),
          level: 'info',
        })
        this.props.disableNotification();
      }

    
    this.props.disableNotification();
    }, 500);
   
return null;
  }

    // close sidebar when
    handleContentClick = event => {
      // close sidebar if sidebar is open and screen size is less than `md`
      if (
        MainLayout.isSidebarOpen() &&
        (this.props.breakpoint === 'xs' ||
          this.props.breakpoint === 'sm' ||
          this.props.breakpoint === 'md')
      ) {
        this.openSidebar('close');
      }
    };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return this.openSidebar('open');
    }
  }

  openSidebar(openOrClose) {
    if (openOrClose === 'open') {
      return document
        .querySelector('.cr-sidebar')
        .classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  }
  
  openFilter(openOrClose) {
    if (openOrClose === 'open') {
      return document
        .querySelector('.cr-filter')
        .classList.add('cr-filter--open');
    }
    document.querySelector('.cr-filter').classList.remove('cr-filter--open');
  }

  render() {
    const { children } = this.props;
    return (
      <main className="cr-app bg-light">
         <Sidebar />
        {this.props.state.notification.notification?this.newFunc():null}
        <Content fluid onClick={this.handleContentClick}>
          <Header />
          <div className="cr-main row">
          <div className=" children col-12">
          {children}
          </div>
          
          <div className="filter">
              <FilterBar   />
          </div>
         
          </div>
         
          <Footer />
        </Content>
       
       
        <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </main>
      
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fireNotification: (payload) => {
      dispatch(fireNotification(payload))
    },
    disableNotification: () => {
      dispatch(disableNotification())
    }
  }
}

const mapStatetoProps = (state) => {
  return ({
    state: state
  })
}

export default withI18n()(connect(mapStatetoProps, mapDispatchToProps)(MainLayout));


