import React from 'react';

class ItemShow extends React.Component {
  render() {
    return (
      <div>
        {this.props.resource}
-
      </div>
    );
  }
}

export default ItemShow;
